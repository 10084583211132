import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select as SelectAntd } from 'antd';
import { subject } from '@casl/ability';
import { Select, Popup, notification, NOTIFICATION_TYPES, Typography, RichArea } from '_atoms';
import * as actions from 'actions';
import { ACTIONS, Can, ROLES, UI, useAbility } from 'permission';
import { BUTTON_TEXT } from 'constants/text';
import { JOB_STATUSES } from 'constants/job';
import { getCompanies, getHrManagers, getServices } from 'store/selectors/commonSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getCandidateJobs } from 'store/selectors/candidateSelectors';
import { dateFormatter } from 'helpers/dateFormatter';
import { STEPS_LABELS } from '../../constants/constants';
import { SELECT_TYPES } from '../../_atoms/Select/constants';

export const PopupReassignCandidate = ({ open, onClose, candidate, onAssign, isSSC }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const candidateJobs = useSelector(getCandidateJobs);
  const ability = useAbility();
  const canAssignRecruiter = ability.can(ACTIONS.READ, subject(UI.ASSIGN_RECRUITER, { status: JOB_STATUSES.OPEN }));
  const defaultRr = ability.can(ACTIONS.READ, subject(UI.ASSIGN_RECRUITER, { status: JOB_STATUSES.OPEN }))
    ? null
    : { value: user.full_name, label: user.full_name };

  const companies = useSelector(getCompanies);
  const services = useSelector(getServices);
  const recruiters = useSelector(getHrManagers);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [service, setService] = useState(null);
  const [job, setJob] = useState(null);
  const [opening, setOpening] = useState(null);
  const [step, setStep] = useState(null);
  const [comment, setComment] = useState('');
  const [jobs, setJobs] = useState([]);
  const [openings, setOpenings] = useState([]);
  const [steps, setSteps] = useState([]);
  const [rR, setRR] = useState(defaultRr);
  const [cvResponsible, setCvResponsible] = useState([]);
  const [cvResponsibleOptions, setCvResponsibleOptions] = useState([]);
  const [candidateSameJob, setCandidateSameJob] = useState(null);

  const showCvsResponsables =
    step?.label === STEPS_LABELS.CONTACT_WITH_CANDIDATE || step?.label === STEPS_LABELS.CV_SCREENING;

  const optionsServices = useMemo(
    () =>
      services.map(item => {
        const company = companies.length ? ` (${companies.find(c => c.uuid === item.company.uuid).name})` : null;
        return { label: item.name + company, value: item.uuid };
      }),
    [services, companies]
  );

  const onChangeService = item => {
    setService(item);
    setJob(null);
    setOpening(null);
    setStep(null);
    setSteps([]);
    setRR(defaultRr);
    setCvResponsible([]);
    actions.getListJobs({ service: item.value, status: 'Open' }, 'v2').then(resp => {
      setJobs(resp.map(i => ({ label: i.name, value: i.uuid })));
    });
    setOpenings([]);
  };

  const onAssignCandidate = () => {
    setCandidateSameJob(null);
    actions
      .setStepToCandidate(job.value, {
        candidate: isSSC ? candidate.candidate_id : candidate.uuid,
        step: step.value,
        responsible_recruiter: canAssignRecruiter ? rR.value : user.uuid,
        potential_opening: opening?.value,
        cv_screening_responsibles: showCvsResponsables ? cvResponsible : [],
        comment,
      })
      .then(() => {
        notification({ message: 'Candidate has been assigned to job.', type: NOTIFICATION_TYPES.SUCCESS });
        onAssign();
      });
  };

  const onJobChange = item => {
    setJob(item);
    setOpening(null);
    setStep(null);
    setRR(defaultRr);
    actions.getOpeningsList(item.value, { status: 'Open' }).then(ops => {
      setOpenings(ops);
      actions
        .getListUsersByRole({
          role: [ROLES.TEAM_LEAD, ROLES.SERVICE_HEAD, ROLES.INTERVIEWER],
          ordering: 'full_name',
        })
        .then(users => {
          const teamLeadsWithOpening = ops
            .filter(o => o.team_lead.roles.find(r => [ROLES.TEAM_LEAD, ROLES.SERVICE_HEAD].includes(r.name)))
            .map(o => o.team_lead.uuid);
          setCvResponsible([...new Set(teamLeadsWithOpening)]);
          setCvResponsibleOptions(users.map(v => ({ value: v.uuid, label: v.full_name })));
        });
    });
    actions.getStepsAssignToJob(item.value).then(resp => {
      const data = resp
        .filter(i => i.ordering_number !== 6)
        .map(itemFiltered => ({ label: itemFiltered.name, value: itemFiltered.uuid }));
      setSteps(data);
    });
  };
  useEffect(() => {
    if (!initialDataLoaded && open) {
      setInitialDataLoaded(true);
      dispatch(actions.getAllCompanies());
      dispatch(actions.getListServices());
      dispatch(
        actions.getListHrManagers({
          role: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD, ROLES.HR_LEAD],
          my_recruiters_group: user.active_role.name === ROLES.RECRUITER_LEAD,
          ordering: 'full_name',
        })
      );
      if (!isSSC) dispatch(actions.getJobs(candidate.uuid));
    }
  }, [initialDataLoaded, open]);

  useEffect(() => {
    setService(null);
    setJob(null);
    setOpening(null);
    setStep(null);
    setSteps([]);
    setJobs([]);
    setOpenings([]);
    setCandidateSameJob(null);
    setRR(null);
    setCvResponsible([]);
    setComment('');
  }, [open]);

  if (candidateSameJob) {
    return (
      <Popup
        open={!!candidateSameJob}
        title="Reassign candidate to the same job?"
        cancelButtonProps={{
          onClick: () => setCandidateSameJob(null),
          children: BUTTON_TEXT.CANCEL,
        }}
        okButtonProps={{
          onClick: onAssignCandidate,
          children: 'Assign to job',
        }}
      >
        {`Candidate is in ${candidateSameJob.active_step.step_status} status in the ${candidateSameJob.job} and assigned to ${candidateSameJob.responsible_recruiter.full_name}. Do you want to reassign candidate to ${step.label} step and to ${rR.label}?`}
      </Popup>
    );
  }

  return (
    <Popup
      open={open}
      title="Assign candidate"
      className="popup-assign-candidate-to-job"
      cancelButtonProps={{
        onClick: onClose,
        children: BUTTON_TEXT.CANCEL,
      }}
      okButtonProps={{
        disabled: !service || !job || !step || (canAssignRecruiter && !rR),
        onClick: () => {
          const sameJob = candidateJobs.find(j => j.uuid === job.value);
          if (sameJob?.opening?.resolution === 'Hired') {
            notification({ message: `Candidate ${sameJob.opening.candidate.full_name_latin} already passed this job` });
            return;
          }
          if (sameJob) setCandidateSameJob(sameJob);
          else onAssignCandidate();
        },
        children: 'Assign',
      }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">
          {candidate.full_name_latin
            ? candidate.full_name_latin
            : `${candidate.first_name_latin} ${candidate.last_name_latin}`}
        </Typography.Text>
        <Typography.Caption className="user-info__subtitle">
          {candidate.first_name_local} {candidate.last_name_local}
        </Typography.Caption>
        <Typography.Caption className="user-info__subtitle">{candidate.speciality}</Typography.Caption>
      </div>
      <Select labelInValue label="Services" value={service} options={optionsServices} onChange={onChangeService} />
      <Select labelInValue label="Job" value={job} disabled={!jobs.length} options={jobs} onChange={onJobChange} />
      <Select
        labelInValue
        label="Potential opening"
        value={opening}
        onChange={setOpening}
        disabled={!job || !openings.length}
        optional
      >
        {openings.map(o => (
          <SelectAntd.Option key={o.uuid} value={o.uuid} label={`${o.level} - ${o.team_lead.full_name}`}>
            <div className="opening-select-option">
              <Typography.Text>{`${o.level} - ${o.team_lead.full_name}`}</Typography.Text>
              <Typography.Caption>{`Expected date: ${dateFormatter(o.expected)}`}</Typography.Caption>
              <Typography.Caption>{o.offices.map(office => office.name).join(', ')}</Typography.Caption>
            </div>
          </SelectAntd.Option>
        ))}
      </Select>
      <Can I={ACTIONS.READ} a={subject(UI.ASSIGN_RECRUITER, { status: JOB_STATUSES.OPEN })}>
        <Select
          labelInValue
          label="Recruiter"
          value={rR}
          disabled={!recruiters.length}
          options={recruiters}
          onChange={setRR}
        />
      </Can>
      <Select
        labelInValue
        label="Step"
        value={step}
        disabled={!steps.length}
        options={steps}
        onChange={item => setStep(item)}
      />
      {showCvsResponsables && (
        <Select
          label="CV screening responsibles"
          value={cvResponsible}
          options={cvResponsibleOptions}
          onChange={r => setCvResponsible(r.map(v => v.value))}
          mode={SELECT_TYPES.MULTIPLE}
          optional
        />
      )}
      <RichArea label="Comment" maxLength={6000} placeholder="Please enter comment" onChange={setComment} optional />
    </Popup>
  );
};
