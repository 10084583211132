export const ROUTES = {
  PORTAL: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  DASHBOARD_RECRUITER: '/dashboard/recruiter',
  DASHBOARD_RECRUITER_LEAD: '/dashboard/recruiter_lead',
  DASHBOARD_HR_LEAD: '/dashboard/hr_lead',
  DASHBOARD_TEAM_LEAD: '/dashboard/team_lead',
  DASHBOARD_SERVICE_HEAD: '/dashboard/service_head',
  DASHBOARD_INTERVIEWER: '/dashboard/interviewer',
  DASHBOARD_RESOURCE_MANAGER: '/dashboard/resource_manager',
  DASHBOARD_LOCATION_MANAGER: '/dashboard/location_manager',
  DASHBOARD_SUPER_ADMIN: '/dashboard/super_admin',
  JOBS: '/jobs',
  JOBS_CREATE: '/jobs/create',
  JOBS_EDIT: '/jobs/:id/edit',
  JOBS_CLONE: '/jobs/:id/clone',
  JOBS_VIEW: '/jobs/:id/show/:tab?/:step?/:candidate?',
  JOBS_OPENING_CREATE: '/jobs/:id/create-opening/:openingId?',
  JOBS_OPENING_CLONE: '/jobs/:id/clone-opening/:openingId?',
  CANDIDATES: '/candidates',
  CANDIDATES_CREATE: '/candidates/create',
  CANDIDATES_VIEW: '/candidates/:id/show',
  CANDIDATES_PREVIEW: '/candidates/preview/:id',
  CANDIDATES_SSC: '/self-submitted-candidates',
  CANDIDATES_VIEW_SSC: '/self-submitted-candidates/:id/show',
  CANDIDATES_SSC_PREVIEW: '/self-submitted-candidates/preview/:id',
  CANDIDATES_EDIT: '/candidates/:id/edit',
  CANDIDATES_SSC_EDIT: '/self-submitted-candidates/:id/edit',
  CANDIDATES_SSC_MERGE_PROFILE: '/self-submitted-candidates/merge-profile/:id/:duplicate_id',
  ANALYTICS: '/analytics',
  ANALYTICS_CANDIDATE: '/analytics/candidates',
  ANALYTICS_REPORTING: '/analytics/reporting',
  ANALYTICS_OPENINGS: '/analytics/openings',
  ANALYTICS_SSC: '/analytics/self-submitted-candidates',
  ANALYTICS_JOB: '/analytics/jobs',
  ANALYTICS_RECRUITERS: '/analytics/recruiters',
  ANALYTICS_TEAM_LEADS: '/analytics/team_leads',
};
