export const BUTTON_TEXT = {
  OK: 'Ok',
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  FIRED_CANDIDATE: 'Fire candidate',
  ASSIGN_TO_JOB: 'Assign to job',
  PRINT_CV: 'Print CV',
  GO_BACK: 'Go Back',
  SEND_TO_EMAIL: 'Send to email',
  EXPORT_EXCEL: 'Export Excel',
  EXPORT: 'Export',
  ADD_RESOURCE_MANAGER: 'Add Resource Manager',
  DELETE_COMPANY: 'Delete Company',
  ADD_COMPANY: 'Add company',
  SAVE_CHANGES: 'Save changes',
  ADD_LOCATION: 'Add location',
  DELETE_LOCATION: 'Delete location',
  CONFIRM: 'Confirm',
  ADD: 'Add',
  DELETE: 'Delete',
  ADD_USER: 'Add User',
  DELETE_SERVICES: 'Delete services',
  ADD_TEAM_LEAD: 'Add Team Lead',
  ADD_SERVICE: 'Add service',
  RESET: 'Reset',
  SAVE: 'Save',
  ADD_OPENING: 'Add Opening',
  SHOW_ALL: 'Show all',
  BACK: 'Back',
  PROCEED: 'Proceed',
  IMPORT: 'Import',
  APPROVE: 'Approve',
  DECLINE: 'Decline',
  CREATE: 'Create',
  VIEW_CANDIDATES: 'View candidates',
  CREATE_CANDIDATE: 'Create Candidate',
  CREATE_JOB: 'Create Job',
  PARTICIPANTS: 'Participants',
  EDIT: 'Edit',
  RESET_FILTERS: 'Reset Filters',
  APPLY: 'Apply',
  BLACKLIST: 'Move to blacklist',
  COMPLETE: 'Complete',
  REJECT: 'Reject',
  SUBMIT: 'Submit',
  ADD_INTERVIEW: 'Add Interview',
  POST_COMMENT: 'Post',
  SUBMIT_DECISION: 'Submit decision',
  HIDE: 'hide',
  MORE: 'more',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  GOOGLE_LOGIN: 'Sign in with Google',
  CLOSE_OPENING: 'Close Opening',
  ADD_CANDIDATE: 'Add Candidate',
  ADD_JOB: 'Add Job',
  SEE_MORE: 'See more',
  SHOW_LESS_DETAILS: 'Show less details',
  SHOW_MORE_DETAILS: 'Show more details',
  DOWNLOAD: 'Download',
  REASSIGN: 'Reassign',
  BACK_TO_PAGE: 'back to page',
  DISCARD: 'discard',
  WITHDRAW: 'Withdraw',
};

export const POPUP_LABELS = {
  FIRED_CANDIDATE: 'Fire candidate?',
  EXPORT_EXCEL: 'Export Excel file to mail',
  SUCCESS: 'Success',
  LOGIN_WELCOME: 'Welcome!\nJust one simple step left',
  LOGIN_EMAIL_ERROR: 'Something went wrong...',
  LOGIN_ERROR: 'Seems that you do not have enough\nauthorisation to use the system.',
  DELETE_OPENING: 'Delete opening',
  CANCEL_INTERVIEW: 'Cancel interview',
  EDIT_CANDIDATE: 'Edit candidate',
  ADD_CANDIDATE: 'Add candidate',
  DELETE_COMMENT: 'Delete comment',
  DELETE_CANDIDATE: 'Delete candidate?',
  SAVE_JOB: 'Save job',
  ADD_JOB: 'Add job',
  ARCHIVE_JOB: 'Archive job',
  UNARCHIVE_JOB: 'Unarchive job',
  DELETE_JOB: 'Delete job',
  REMOVE_RECRUITER: 'Remove recruiter',
  ASSIGN_CANDIDATE: 'Assign candidate',
  ASSIGN_RECRUITERS: 'Assign recruiters',
  LIST_DUPLICATES: 'List of potential duplicates',
  SAVING_CHANGES: 'Saving changes',
  CLOSE_OPENING: 'Close Opening',
  DECLINE_OPENING: 'Decline opening',
  ADD_PARTICIPANTS: 'Add participants',
  DISCARD_CHANGES: 'Discard changes',
};

export const POPUP_BODY = {
  FILE_SENT: 'The file has been send successfully.\nPlease check you email.',
  EXPORT_EXCEL: 'The Excel file will be sent to your email.\nPlease be aware that this may take a while.',
  LOGIN_WELCOME: 'Please sign in with LeverX Google account\nto access the system',
  LOGIN_EMAIL_ERROR:
    'Seems that you are trying to sign in with a different Google account.\nPlease use LeverX Google account to access the system',
  LOGIN_ERROR: 'Please contact admin to get access to the system',
  CRITICAL_ERROR: 'Critical error. Please contact admin',
  DELETE_OPENING: 'Are you sure you want to delete opening?',
  CANCEL_INTERVIEW: 'Are you sure you want to cancel interview?',
  SAVE_CHANGES: 'Are you sure you want to save changes?',
  ADD_CANDIDATE: 'Are you sure you want to create new candidate?',
  CANCEL_CHANGES: 'Are you sure you want to cancel changes?',
  DELETE_COMMENT: 'Are you sure you want to delete comment?',
  DELETE_CANDIDATE: 'All the information about candidate will be lost. Do you want to delete candidate?',
  ADD_JOB: 'Are you sure you want to create new job?',
  ARCHIVE_JOB: 'Are you sure you want to archive job?',
  UNARCHIVE_JOB: 'Are you sure you want to unarchive job?',
  DELETE_JOB: 'All the progress will be lost. Are you sure you want to delete job?',
  REMOVE_RECRUITER: 'Are you sure you want to remove recruiter from the job?',
  ASSIGN_CANDIDATE: 'Are you sure you want to assign this candidate to you?',
  CHANGES_NOT_SAVED: 'Changes will not be saved',
};

export const OTHER_TEXT = {
  USER_LOADING: 'Please wait while loading',
  OPENINGS_PENDING_APPROVAL: 'Openings pending approval',
  NO_OPENINGS: 'No openings to approve',
};
