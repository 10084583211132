import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { subject } from '@casl/ability';
import cn from 'classnames';
import { AtsButtonMenu, AtsSkeleton, Button, BUTTON_TYPES, Card, Tabs, User } from '_atoms';
import { PopupAssignRecruiters } from '_molecules/PopupAssignRecruiters';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import history from 'helpers/history';
import useClickOutside from 'helpers/useClickOutside';
import { useQuery } from 'helpers/useLocation';
import { ACTIONS, Can, ROLES, SUBJECTS, UI, useAbility } from 'permission';
import { BUTTON_TEXT } from 'constants/text';
import { JOB_STATUSES } from 'constants/job';
import { assignHrToJobs } from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getOpeningsLoading } from 'store/selectors/commonSelectors';
import { ReactComponent as Arrow } from 'dist/icons/caret-down.svg';
import { ReactComponent as Delete } from 'dist/icons/vector.svg';
import { STEPS_OPTIONS } from './constants';
import { getJobStatusType } from './utils';
import './style.scss';

export const JobHeader = ({
  name,
  status,
  service,
  teamLead,
  resourceManager,
  recruiters,
  recruiterLeads,
  ...props
}) => {
  const { id: jobId } = useParams();
  const { team } = useQuery();
  const ability = useAbility();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [ref, open, click] = useClickOutside(team);
  const [openAssign, setOpenAssign] = useState(false);
  const openingLoading = useSelector(getOpeningsLoading);

  const onlyEditOption =
    ability.can(ACTIONS.UPDATE, SUBJECTS.JOB) &&
    !ability.can(ACTIONS.READ, UI.CLONE_JOB) &&
    (!ability.can(ACTIONS.READ, UI.ARCHIVE_JOB) || !props.showArchive) &&
    (!ability.can(ACTIONS.DELETE, SUBJECTS.JOB) || !props.showDelete) &&
    user.active_role.name === ROLES.RECRUITER;

  const accessToJob =
    user.active_role.name === ROLES.RECRUITER_LEAD ? recruiterLeads.find(r => r.uuid === user.uuid) : true;

  const teamLength =
    recruiters.length +
    recruiterLeads.length +
    Number(!!teamLead) +
    Number(!!resourceManager) +
    Number(!!service?.service_head);

  const isArchived = status === JOB_STATUSES.ARCHIVED;

  const onOpenAssignPopup = () => {
    setOpenAssign(true);
    click({});
  };
  const onCloseAssignPopup = () => setOpenAssign(false);
  const onSaveRecruiters = (list, asRecruiters) => {
    dispatch(assignHrToJobs(jobId, list, asRecruiters)).then(() => {
      setOpenAssign(false);
    });
  };

  if (!name) {
    return (
      <Card className="job-header">
        <AtsSkeleton height={50} width="100%" />
      </Card>
    );
  }

  return (
    <Card className="job-header">
      <div className="job-header__job">
        <div className="job-header__job-name">
          <Typography.Title1>{name}</Typography.Title1>
          <Status type={getJobStatusType(status)}>{status}</Status>
        </div>
        <Link to={`/jobs?services=[{"value":"${service?.uuid}","label":"${service?.name}"}]`}>
          <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>{service?.name}</Typography.Title3>
        </Link>
      </div>
      <div className="job-header__extra">
        <div className={cn('job-header__job-team', { open })} ref={ref}>
          <Button type={BUTTON_TYPES.GHOST}>
            team ({teamLength})
            <Arrow />
          </Button>
          <Card className="team-list">
            {teamLead && (
              <>
                <Typography.Label className="team-list__label">{ROLES.TEAM_LEAD}</Typography.Label>
                <User avatar={teamLead.avatar} fullName={teamLead.full_name} roles={[{ name: ROLES.TEAM_LEAD }]} />
              </>
            )}
            {resourceManager && (
              <>
                <Typography.Label className="team-list__label">{ROLES.RESOURCE_MANAGER}</Typography.Label>
                <User
                  avatar={resourceManager.avatar}
                  fullName={resourceManager.full_name}
                  roles={[{ name: ROLES.RESOURCE_MANAGER }]}
                />
              </>
            )}
            {service?.service_head && (
              <>
                <Typography.Label className="team-list__label">{ROLES.SERVICE_HEAD}</Typography.Label>
                <User
                  avatar={service.service_head.avatar}
                  fullName={service.service_head.full_name}
                  roles={[{ name: ROLES.SERVICE_HEAD }]}
                />
              </>
            )}
            {!!recruiterLeads.length && (
              <>
                <Typography.Label className="team-list__label">{ROLES.RECRUITER_LEAD}(s)</Typography.Label>
                {recruiterLeads.map((r, index) => (
                  <div key={r.full_name + index} className="recruiter-wrapper">
                    <User avatar={r.avatar} fullName={r.full_name} roles={[{ name: ROLES.RECRUITER_LEAD }]} />
                    <Can I={ACTIONS.READ} a={subject(UI.UNASSIGN_RECRUITER_LEAD, { status })}>
                      <Delete className="recruiter-wrapper__delete" onClick={() => props.deleteRecruiter(r)} />
                    </Can>
                  </div>
                ))}
              </>
            )}
            {!!recruiters.length && (
              <>
                <Typography.Label className="team-list__label">{ROLES.RECRUITER}(s)</Typography.Label>
                {recruiters.map((r, index) => (
                  <div key={r.full_name + index} className="recruiter-wrapper">
                    <User avatar={r.avatar} fullName={r.full_name} roles={[{ name: ROLES.RECRUITER }]} />
                    <Can I={ACTIONS.READ} a={subject(UI.UNASSIGN_RECRUITER, { status })} passThrough>
                      {access =>
                        access &&
                        (user.active_role.name !== ROLES.RECRUITER_LEAD || r.recruiter_leads.includes(user.uuid)) && (
                          <Delete className="recruiter-wrapper__delete" onClick={() => props.deleteRecruiter(r)} />
                        )
                      }
                    </Can>
                  </div>
                ))}
              </>
            )}
            <Can I={ACTIONS.READ} a={subject(UI.ASSIGN_RECRUITER, { status })} passThrough>
              {access =>
                access &&
                accessToJob && (
                  <Button className="assign-recruiter" type={BUTTON_TYPES.PRIMARY} onClick={onOpenAssignPopup}>
                    assign recruiters
                  </Button>
                )
              }
            </Can>
          </Card>
        </div>
        {props.showAddOpening && (
          <Button className="add-opening" onClick={() => history.push(`/jobs/${jobId}/create-opening`)}>
            {BUTTON_TEXT.ADD_OPENING}
          </Button>
        )}
        {onlyEditOption && !openingLoading && (
          <Button className="add-opening" onClick={() => history.push(`/jobs/${jobId}/edit`)}>
            Edit
          </Button>
        )}
        <Can I={ACTIONS.READ} a={UI.ARCHIVE_JOB}>
          {isArchived && (
            <Button type={BUTTON_TYPES.PRIMARY} className="add-opening" onClick={() => props.showPopupUnarchive(true)}>
              Unarchive Job
            </Button>
          )}
        </Can>
        {(!onlyEditOption || openingLoading) && (
          <AtsButtonMenu disabled={openingLoading}>
            <Can I={ACTIONS.UPDATE} a={SUBJECTS.JOB}>
              <li role="none" onClick={() => history.push(`/jobs/${jobId}/edit`)}>
                Edit
              </li>
            </Can>
            <Can I={ACTIONS.READ} a={UI.CLONE_JOB}>
              <li role="none" onClick={() => history.push(`/jobs/${jobId}/clone`)}>
                Clone
              </li>
            </Can>
            <Can I={ACTIONS.READ} a={UI.ARCHIVE_JOB}>
              {props.showArchive && (
                <li role="none" onClick={() => props.showPopupArchive(true)}>
                  Archive
                </li>
              )}
            </Can>
            <Can I={ACTIONS.DELETE} a={SUBJECTS.JOB}>
              {props.showDelete && (
                <li role="none" className="active" onClick={() => props.showPopupDelete(true)}>
                  Delete
                </li>
              )}
            </Can>
          </AtsButtonMenu>
        )}
      </div>
      <Tabs
        activeTab={props.activeTab}
        onTabClick={props.onTabClick}
        items={STEPS_OPTIONS}
        className="job-header__tabs"
      />
      <PopupAssignRecruiters
        open={openAssign}
        onClose={onCloseAssignPopup}
        onSave={onSaveRecruiters}
        currentRecruiters={[...recruiterLeads, ...recruiters].map(r => ({
          value: r.uuid,
          is_assigned_as_recruiter: !!r.is_assigned_as_recruiter,
          avatar: r.avatar,
        }))}
      />
    </Card>
  );
};
