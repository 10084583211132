import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '_organisms';
import {
  CandidateList,
  CandidateProfile,
  CandidateProfilePreview,
  CandidatesAdd,
  CreateOpening,
  DashboardHRLead,
  DashboardInterviewer,
  DashboardLocationManager,
  DashboardRecruiterLead,
  DashboardRecruiter,
  DashboardResourceManager,
  DashboardServiceHead,
  DashboardSuperAdmin,
  DashboardTeamLead,
  Jobs as JobsList,
  Login,
  MergeProfile,
} from '_templates';
import { getUser } from 'store/selectors/authorizeSelectors';
import { changeCandidateFilter } from 'store/reducers/candidateReducer';
import { changeJobFilter } from 'store/reducers/jobReducer';
import history from 'helpers/history';
import { ACTIONS, UI, useAbility } from 'permission';
import { ROUTES } from 'router/constants';
import AddJobs from 'containers/Jobs/AddJobs';
import ViewJob from 'containers/Jobs/ViewJob';
import { AnalyticsWrapper } from 'containers/Analytics/Analytics';

export const Router = () => {
  const ability = useAbility();
  const { pathname } = useLocation();
  const user = useSelector(getUser);
  const isAnalyticsAble = user && ability.can(ACTIONS.READ, UI.ANALYTICS_PAGE);

  useEffect(() => {
    if (user) {
      const role = user.active_role?.name;
      const dashboard = `${ROUTES.DASHBOARD}/${role.toLowerCase().replace(/\s/g, '_')}`;
      if (pathname.includes(ROUTES.DASHBOARD) && pathname !== dashboard) {
        history.replace(dashboard);
      }
    }
  }, [pathname, user]);

  return (
    <>
      <Route
        path={[ROUTES.DASHBOARD, ROUTES.JOBS, ROUTES.CANDIDATES, ROUTES.CANDIDATES_SSC, ROUTES.ANALYTICS]}
        component={Header}
      />
      <Route path={[ROUTES.JOBS, ROUTES.CANDIDATES]} component={OnClearFilters} />
      <Switch>
        <Route exact path={ROUTES.PORTAL} render={() => <Redirect to={ROUTES.DASHBOARD} />} />

        <Route path={ROUTES.DASHBOARD_SUPER_ADMIN} component={DashboardSuperAdmin} />
        <Route path={ROUTES.DASHBOARD_HR_LEAD} component={DashboardHRLead} />
        <Route path={ROUTES.DASHBOARD_RECRUITER} component={DashboardRecruiter} />
        <Route path={ROUTES.DASHBOARD_RECRUITER_LEAD} component={DashboardRecruiterLead} />
        <Route path={ROUTES.DASHBOARD_RESOURCE_MANAGER} component={DashboardResourceManager} />
        <Route path={ROUTES.DASHBOARD_TEAM_LEAD} component={DashboardTeamLead} />
        <Route path={ROUTES.DASHBOARD_LOCATION_MANAGER} component={DashboardLocationManager} />
        <Route path={ROUTES.DASHBOARD_SERVICE_HEAD} component={DashboardServiceHead} />
        <Route path={ROUTES.DASHBOARD_INTERVIEWER} component={DashboardInterviewer} />

        <Route path={ROUTES.CANDIDATES_SSC_MERGE_PROFILE} component={MergeProfile} />

        <Route exact path={ROUTES.JOBS} component={JobsList} />
        <Route path={ROUTES.JOBS_CREATE} component={AddJobs} />
        <Route path={ROUTES.JOBS_VIEW} render={({ match }) => <ViewJob {...match.params} />} />
        <Route path={ROUTES.JOBS_EDIT} render={({ match }) => <AddJobs id={match.params.id} />} />
        {/* <Route path={ROUTES.JOBS_CLONE} render={({ match }) => <AddJobs id={match.params.id} isClone />} /> */}
        <Route path={ROUTES.JOBS_OPENING_CREATE} component={CreateOpening} />
        <Route path={ROUTES.JOBS_OPENING_CLONE} render={props => <CreateOpening isClone {...props} />} />

        <Route
          path={ROUTES.CANDIDATES_VIEW_SSC}
          render={({ match }) => <CandidateProfile id={match.params.id} isSSC />}
        />
        <Route path={ROUTES.CANDIDATES_SSC_PREVIEW} render={() => <CandidateProfilePreview isSSC />} />
        <Route exact path={ROUTES.CANDIDATES} component={CandidateList} />
        <Route path={ROUTES.CANDIDATES_CREATE} component={CandidatesAdd} />
        <Route path={ROUTES.CANDIDATES_VIEW} component={CandidateProfile} />
        <Route path={ROUTES.CANDIDATES_PREVIEW} component={CandidateProfilePreview} />
        <Route path={ROUTES.CANDIDATES_EDIT} component={CandidatesAdd} />
        <Route path={ROUTES.CANDIDATES_SSC_EDIT} render={() => <CandidatesAdd isSSC />} />

        {isAnalyticsAble && <Route path={ROUTES.ANALYTICS} component={AnalyticsWrapper} />}
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route render={({ location }) => <Redirect to={{ ...location, state: { is404: true } }} />} />
      </Switch>
    </>
  );
};

const OnClearFilters = () => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(changeCandidateFilter({}));
      dispatch(changeJobFilter({}));
    },
    []
  );

  return null;
};

export default Router;
