import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Popup, Button, BUTTON_TYPES, User } from '_atoms';
import { fetchWrapper } from 'helpers/helpers';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ROLES } from 'permission';
import { BUTTON_TEXT } from 'constants/text';
import { TEXT } from './constants';
import './styles.scss';

const getRecruiters = body =>
  fetchWrapper({
    url: 'users/',
    body,
  }).catch(() => []);

export const PopupReassignResponsibleRecruiter = ({ open, loading, onOk, onClose, currentRecruiter }) => {
  const user = useSelector(getUser);
  const [recruiter, setRecruiter] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const onAssignToMe = () => {
    setRecruiter(options.find(r => r.value === user.uuid));
  };

  const showAssignToMe = useMemo(
    () => options.find(r => r.value === user.uuid) && recruiter?.value !== user.uuid,
    [options, recruiter]
  );

  useEffect(() => {
    if (!open) {
      setRecruiter(null);
      setOptions([]);
    } else {
      setOptionsLoading(true);
      (user.active_role.name === ROLES.RECRUITER_LEAD
        ? Promise.all([
            getRecruiters({
              exclude_uuid__in: currentRecruiter.uuid,
              role: [ROLES.RECRUITER],
              my_recruiters_group: true,
            }),
            getRecruiters({
              exclude_uuid__in: currentRecruiter.uuid,
              role: [ROLES.RECRUITER_LEAD],
            }),
          ]).then(resp => resp.flat())
        : getRecruiters({
            exclude_uuid__in: currentRecruiter.uuid,
            role: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD],
          })
      )
        .then(users =>
          setOptions([...new Map(users.map(u => [u.uuid, { value: u.uuid, label: u.full_name }])).values()])
        )
        .finally(() => setOptionsLoading(false));
    }
  }, [open, currentRecruiter]);
  return (
    <Popup
      className="popup-reassign-recruiter-recruiter"
      open={open}
      loading={loading}
      title={TEXT.TITLE}
      cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
      okButtonProps={{ disabled: !recruiter, onClick: () => onOk(recruiter.value), children: BUTTON_TEXT.REASSIGN }}
    >
      <User
        className="popup-reassign-recruiter__user"
        fullName={currentRecruiter?.full_name}
        avatar={currentRecruiter?.avatar}
      />
      <Select
        className="popup-reassign-recruiter__select"
        label={TEXT.REASSIGN_TO}
        value={recruiter}
        onChange={setRecruiter}
        options={options}
        loading={optionsLoading}
        filterOption={(v, o) => o.label.toLowerCase().includes(v.toLowerCase())}
      />
      {showAssignToMe && (
        <Button className="popup-reassign-recruiter__assign-to-me" type={BUTTON_TYPES.TEXT} onClick={onAssignToMe}>
          {TEXT.ASSIGN_TO_ME}
        </Button>
      )}
    </Popup>
  );
};
