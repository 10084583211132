import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { subject } from '@casl/ability';
import { Button, BUTTON_TYPES, File, Tooltip } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { Priority } from '_atoms/Priority';
import { getJobStatusColor } from '_templates/Candidates/utils';
import { stepsStatuses } from 'constants/constants';
import { BUTTON_TEXT } from 'constants/text';
import { OPENING_STATUS_TYPES, OPENING_STATUSES } from 'constants/opening';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import { ACTIONS, Can, UI } from 'permission';

export const COMPANY_ALL = { value: '', label: 'All' };

export const OPENING_STATUSES_OPTIONS = [
  OPENING_STATUSES.SH_APPROVAL,
  OPENING_STATUSES.RM_APPROVAL,
  OPENING_STATUSES.LM_APPROVAL,
  OPENING_STATUSES.HRL_APPROVAL,
  OPENING_STATUSES.OPEN,
  OPENING_STATUSES.WAITING_FOR_ENTRY,
].map(value => ({ value, label: value }));

const headerCellRender = (title, field, key, sort, setSorting) => {
  const order = sort.key === key ? sort.order : null;
  const onClick = () => setSorting({ field, key, order: order === 'asc' ? 'desc' : 'asc' });
  return (
    <>
      {title}
      <Sort className={order} onClick={onClick} />
    </>
  );
};

export const COLUMNS_OPENINGS_SERVICE_TOP = [
  {
    title: 'Job',
    sorter: true,
    dataIndex: 'job',
    rowKey: 'job',
    width: 164,
    render: () => null,
  },
  {
    title: 'Level',
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    render: () => null,
  },
  {
    title: 'Creation date',
    dataIndex: 'creation_date',
    rowKey: 'creation_date',
    width: 120,
    sorter: true,
    render: () => null,
  },
  {
    title: 'RM approval',
    dataIndex: 'rm_approval',
    rowKey: 'rm_approval',
    width: 120,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 164,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 120,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 208,
    fixed: 'right',
    render: () => null,
  },
];

export const COLUMNS_OPENINGS_SERVICE = onAction => [
  {
    title: 'Job',
    dataIndex: 'job',
    rowKey: 'job',
    width: 164,
    ellipsis: true,
    className: 'all-openings__job',
    render: data => (
      <a href={`/jobs/${data.job_uuid}/show/openings`} target="_blank" rel="noreferrer noopener">
        <Priority priority={data.priority} />
        <Tooltip label={data.job_name}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name}</Typography.Text>
        </Tooltip>
      </a>
    ),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 84,
    className: 'all-openings__level',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Creation date',
    dataIndex: 'creation_date',
    rowKey: 'creation_date',
    width: 120,
    className: 'all-openings__creation-date',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'RM approval',
    dataIndex: 'rm_approval',
    rowKey: 'rm_approval',
    width: 120,
    className: 'all-openings__rm-approval',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 164,
    className: 'all-openings__team-lead',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 120,
    className: 'all-openings__expected-date',
    render: ({ date, error }) => (
      <Typography.Text className={cn({ error })} weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {date}
      </Typography.Text>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    className: 'all-openings__status',
    render: status => <Status type={OPENING_STATUS_TYPES[status]}>{status}</Status>,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 208,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'all-openings__action',
    render: ({ status, ...data }) => (
      <Can I={ACTIONS.READ} a={subject(UI.APPROVE_DECLINE, { status })}>
        <Button onClick={() => onAction(data, 'decline')}>{BUTTON_TEXT.DECLINE}</Button>
        <Button onClick={() => onAction(data, 'approve')} type={BUTTON_TYPES.PRIMARY}>
          {BUTTON_TEXT.APPROVE}
        </Button>
      </Can>
    ),
  },
];

export const COLUMNS_OPENINGS_JOB_TOP = [
  {
    title: 'Level',
    sorter: true,
    dataIndex: 'level',
    rowKey: 'level',
    width: 122,
    render: () => null,
  },
  {
    title: 'Creation date',
    dataIndex: 'creation_date',
    rowKey: 'creation_date',
    width: 138,
    sorter: true,
    render: () => null,
  },
  {
    title: 'RM approval',
    dataIndex: 'rm_approval',
    rowKey: 'rm_approval',
    width: 138,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 232,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 138,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    sorter: true,
    render: () => null,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 212,
    fixed: 'right',
    render: () => null,
  },
];

export const COLUMNS_OPENINGS_JOB = onAction => [
  {
    title: 'Level',
    dataIndex: 'level',
    rowKey: 'level',
    width: 122,
    ellipsis: true,
    className: 'all-openings__level',
    render: ({ level, priority }) => (
      <>
        <Priority priority={priority} />
        <Tooltip label={level}>
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{level}</Typography.Text>
        </Tooltip>
      </>
    ),
  },
  {
    title: 'Creation date',
    dataIndex: 'creation_date',
    rowKey: 'creation_date',
    width: 138,
    className: 'all-openings__creation-date',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'RM approval',
    dataIndex: 'rm_approval',
    rowKey: 'rm_approval',
    width: 138,
    ellipsis: true,
    className: 'all-openings__rm-approval',
    render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
  },
  {
    title: 'Team lead',
    dataIndex: 'team_lead',
    rowKey: 'team_lead',
    width: 232,
    ellipsis: true,
    className: 'all-openings__team-lead',
    render: data => (
      <Tooltip label={data}>
        <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>
      </Tooltip>
    ),
  },
  {
    title: 'Expected date',
    dataIndex: 'expected_date',
    rowKey: 'expected_date',
    width: 138,
    className: 'all-openings__expected-date',
    render: ({ date, error }) => (
      <Typography.Text className={cn({ error })} weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {date}
      </Typography.Text>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    rowKey: 'status',
    width: 164,
    className: 'all-openings__status',
    render: status => <Status type={OPENING_STATUS_TYPES[status]}>{status}</Status>,
  },
  {
    title: 'Required action',
    dataIndex: 'action',
    rowKey: 'action',
    width: 212,
    ellipsis: true,
    fixed: 'right',
    hideMobileTitle: true,
    className: 'all-openings__action',
    render: ({ status, ...data }) => (
      <Can I={ACTIONS.READ} a={subject(UI.APPROVE_DECLINE, { status })}>
        <Button onClick={() => onAction(data, 'decline')}>{BUTTON_TEXT.DECLINE}</Button>
        <Button onClick={() => onAction(data, 'approve')} type={BUTTON_TYPES.PRIMARY}>
          {BUTTON_TEXT.APPROVE}
        </Button>
      </Can>
    ),
  },
];

export const OPENINGS_GROUP = [
  { value: 'service', label: 'Service', tableData: 'job' },
  { value: 'job', label: 'Job', tableData: 'service' },
];

export const CANDIDATES_GROUP = [
  { value: '', label: 'None', tableData: '' },
  { value: 'actions', label: 'Action', tableData: 'actions' },
  { value: 'jobs', label: 'Job', tableData: 'jobs' },
  { value: 'steps', label: 'Step', tableData: 'steps' },
];

export const COLUMNS_CANDIDATES = (sort, setSorting, leadDashboard, userUuid, expanded, setExpanded, onAction) => {
  const columns = [
    {
      title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
      sorter: true,
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: leadDashboard ? 160 : 216,
      className: 'active-candidates__name',
      render: data => (
        <Tooltip label={data.full_name_latin}>
          <Link to={data.url} target="_blank">
            <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
            <Typography.Caption>{data.full_name_local}</Typography.Caption>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: headerCellRender('Level', 'level', 'level', sort, setSorting),
      sorter: true,
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      className: 'active-candidates__level',
      render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
    },
    {
      title: headerCellRender('Job', 'job', 'job', sort, setSorting),
      dataIndex: 'job',
      rowKey: 'job',
      width: leadDashboard ? 160 : 216,
      sorter: true,
      className: 'active-candidates__job',
      render: (data, { candidate }) =>
        data.job_name ? (
          <>
            <Tooltip label={data.job_name}>
              <a
                href={`/jobs/${data.uuid}/show/steps/${data.step_uuid}/${candidate.uuid}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name}</Typography.Text>
              </a>
            </Tooltip>
            <Typography.Caption>{data.service}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: data => (
        <Tooltip label={data.full_name}>
          <Typography.Text>{data.full_name}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 160,
      className: 'active-candidates__cvs',
      render: (data, { uuid }) => (
        <>
          {data.map(
            (file, idx) =>
              (expanded[uuid] || idx < 2) && !!file.file && <File key={file.file} file={file} size="small" />
          )}
          {data.length > 2 && (
            <Button type={BUTTON_TYPES.TEXT} onClick={() => setExpanded(d => ({ ...d, [uuid]: !d[uuid] }))}>
              {expanded[uuid] ? 'Show less' : 'Show more'}
            </Button>
          )}
        </>
      ),
    },
    {
      title: headerCellRender('Status & Step', 'status_step', 'status', sort, setSorting),
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      sorter: true,
      className: 'active-candidates__status-step',
      render: data =>
        data.step ? (
          <>
            <Status type={getJobStatusColor(data.status)}>{data.status}</Status>
            <Typography.Caption>{data.step}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      hideMobileTitle: true,
      className: 'active-candidates__action',
      render: ({ text, subtext, time, link, button, recruiter_uuid }, record) => (
        <>
          {text && <Typography.Text className="text">{text}</Typography.Text>}
          {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
          {time && <Typography.Caption className="time">{time}</Typography.Caption>}
          {link && (
            <a className="link" href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          )}
          {button && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={recruiter_uuid && userUuid !== recruiter_uuid}
              onClick={onAction(record)}
            >
              {button.label}
            </Button>
          )}
          {record.feedback?.is_draft && <span className="draft-status">Saved as draft</span>}
        </>
      ),
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const COLUMNS_CANDIDATES_ACTION_TOP = (sort, setSorting, leadDashboard) => {
  const columns = [
    {
      title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
      sorter: true,
      dataIndex: 'candidate',
      rowKey: 'candidate_name',
      width: leadDashboard ? 160 : 216,
      className: 'active-candidates__name',
      render: () => null,
    },
    {
      title: headerCellRender('Level', 'level', 'level', sort, setSorting),
      sorter: true,
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      className: 'active-candidates__level',
      render: () => null,
    },
    {
      title: headerCellRender('Job', 'job', 'job', sort, setSorting),
      sorter: true,
      dataIndex: 'job',
      rowKey: 'job',
      width: leadDashboard ? 160 : 216,
      className: 'active-candidates__job',
      render: () => null,
    },
    {
      title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: () => null,
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 162,
      className: 'active-candidates__cvs',
      render: () => null,
    },
    {
      title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      sorter: true,
      className: 'active-candidates__status-step',
      render: () => null,
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      className: 'active-candidates__action',
      render: () => null,
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const COLUMNS_CANDIDATES_ACTION = (userUuid, leadDashboard, expanded, setExpanded, onAction) => {
  const columns = [
    {
      title: 'Candidate name',
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: leadDashboard ? 160 : 216,
      ellipsis: true,
      className: 'active-candidates__name',
      render: data => (
        <Tooltip label={data.full_name_latin}>
          <Link to={data.url} target="_blank">
            <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
            <Typography.Caption>{data.full_name_local}</Typography.Caption>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      ellipsis: true,
      className: 'active-candidates__level',
      render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
    },
    {
      title: 'Job',
      dataIndex: 'job',
      rowKey: 'job',
      width: leadDashboard ? 160 : 216,
      ellipsis: true,
      className: 'active-candidates__job',
      render: (data, { candidate }) =>
        data.job_name ? (
          <>
            <Tooltip label={data.job_name}>
              <a
                href={`/jobs/${data.uuid}/show/steps/${data.step_uuid}/${candidate.uuid}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name}</Typography.Text>
              </a>
            </Tooltip>
            <Typography.Caption>{data.service}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: 'Recruiter',
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: data => (
        <Tooltip label={data.full_name}>
          <Typography.Text>{data.full_name}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 160,
      className: 'active-candidates__cvs',
      render: (data, { uuid }) => (
        <>
          {data.map(
            (file, idx) =>
              (expanded[uuid] || idx < 2) && !!file.file && <File key={file.file} file={file} size="small" />
          )}
          {data.length > 2 && (
            <Button type={BUTTON_TYPES.TEXT} onClick={() => setExpanded(d => ({ ...d, [uuid]: !d[uuid] }))}>
              {expanded[uuid] ? 'Show less' : 'Show more'}
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      ellipsis: true,
      className: 'active-candidates__status',
      render: ({ step, status }) =>
        step ? (
          <>
            <Status type={getJobStatusColor(status)}>{status}</Status>
            <Typography.Caption>{step}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      hideMobileTitle: true,
      ellipsis: true,
      className: 'active-candidates__action',
      render: ({ text, subtext, time, link, button, recruiter_uuid }, record) => (
        <>
          {text && <Typography.Text className="text">{text}</Typography.Text>}
          {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
          {time && <Typography.Caption className="time">{time}</Typography.Caption>}
          {link && (
            <a className="link" href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          )}
          {button && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={recruiter_uuid && userUuid !== recruiter_uuid}
              onClick={onAction(record)}
            >
              {button.label}
            </Button>
          )}
        </>
      ),
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const COLUMNS_CANDIDATES_JOB_TOP = (sort, setSorting, leadDashboard) => {
  const columns = [
    {
      title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
      sorter: true,
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: leadDashboard ? 160 : 216,
      className: 'active-candidates__name',
      render: () => null,
    },
    {
      title: headerCellRender('Level', 'level', 'level', sort, setSorting),
      sorter: true,
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      className: 'active-candidates__level',
      render: () => null,
    },
    {
      title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: () => null,
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 160,
      className: 'active-candidates__cvs',
      render: () => null,
    },
    {
      title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      sorter: true,
      className: 'active-candidates__status-step',
      render: () => null,
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      className: 'active-candidates__action',
      render: () => null,
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const COLUMNS_CANDIDATES_JOB = (userUuid, leadDashboard, expanded, setExpanded, onAction) => {
  const columns = [
    {
      title: 'Candidate name',
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: leadDashboard ? 160 : 216,
      ellipsis: true,
      className: 'active-candidates__name',
      render: data => (
        <Tooltip label={data.full_name_latin}>
          <Link to={data.url} target="_blank">
            <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
            <Typography.Caption>{data.full_name_local}</Typography.Caption>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      ellipsis: true,
      className: 'active-candidates__level',
      render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
    },
    {
      title: 'Recruiter',
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: data => (
        <Tooltip label={data.full_name}>
          <Typography.Text>{data.full_name}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 160,
      className: 'active-candidates__cvs',
      render: (data, { uuid }) => (
        <>
          {data.map(
            (file, idx) =>
              (expanded[uuid] || idx < 2) && !!file.file && <File key={file.file} file={file} size="small" />
          )}
          {data.length > 2 && (
            <Button type={BUTTON_TYPES.TEXT} onClick={() => setExpanded(d => ({ ...d, [uuid]: !d[uuid] }))}>
              {expanded[uuid] ? 'Show less' : 'Show more'}
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      ellipsis: true,
      className: 'active-candidates__status',
      render: ({ step, status }) =>
        step ? (
          <>
            <Status type={getJobStatusColor(status)}>{status}</Status>
            <Typography.Caption>{step}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      hideMobileTitle: true,
      ellipsis: true,
      className: 'active-candidates__action',
      render: ({ text, subtext, time, link, button, recruiter_uuid }, record) => (
        <>
          {text && <Typography.Text className="text">{text}</Typography.Text>}
          {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
          {time && <Typography.Caption className="time">{time}</Typography.Caption>}
          {link && (
            <a className="link" href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          )}
          {button && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={recruiter_uuid && userUuid !== recruiter_uuid}
              onClick={onAction(record)}
            >
              {button.label}
            </Button>
          )}
        </>
      ),
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const COLUMNS_CANDIDATES_STEP_TOP = (sort, setSorting, leadDashboard) => {
  const columns = [
    {
      title: headerCellRender('Candidate name', 'candidate', 'candidate', sort, setSorting),
      sorter: true,
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: leadDashboard ? 160 : 216,
      className: 'active-candidates__name',
      render: () => null,
    },
    {
      title: headerCellRender('Level', 'level', 'level', sort, setSorting),
      sorter: true,
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      className: 'active-candidates__level',
      render: () => null,
    },
    {
      title: headerCellRender('Job', 'job', 'job', sort, setSorting),
      sorter: true,
      dataIndex: 'job',
      rowKey: 'job',
      width: leadDashboard ? 160 : 216,
      className: 'active-candidates__job',
      render: () => null,
    },
    {
      title: headerCellRender('Recruiter', 'recruiter', 'recruiter', sort, setSorting),
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: () => null,
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 160,
      className: 'active-candidates__cvs',
      render: () => null,
    },
    {
      title: headerCellRender('Status & Step', 'status_step', 'status_step', sort, setSorting),
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      sorter: true,
      className: 'active-candidates__status-step',
      render: () => null,
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      className: 'active-candidates__action',
      render: () => null,
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const COLUMNS_CANDIDATES_STEP = (userUuid, leadDashboard, expanded, setExpanded, onAction) => {
  const columns = [
    {
      title: 'Candidate name',
      dataIndex: 'candidate',
      rowKey: 'candidate',
      width: leadDashboard ? 160 : 216,
      ellipsis: true,
      className: 'active-candidates__name',
      render: data => (
        <Tooltip label={data.full_name_latin}>
          <Link to={data.url} target="_blank">
            <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.full_name_latin}</Typography.Text>
            <Typography.Caption>{data.full_name_local}</Typography.Caption>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      rowKey: 'level',
      width: leadDashboard ? 84 : 128,
      ellipsis: true,
      className: 'active-candidates__level',
      render: data => <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data}</Typography.Text>,
    },
    {
      title: 'Job',
      dataIndex: 'job',
      rowKey: 'job',
      width: leadDashboard ? 160 : 216,
      ellipsis: true,
      className: 'active-candidates__job',
      render: (data, { candidate }) =>
        data.job_name ? (
          <>
            <Tooltip label={data.job_name}>
              <a
                href={`/jobs/${data.uuid}/show/steps/${data.step_uuid}/${candidate.uuid}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>{data.job_name}</Typography.Text>
              </a>
            </Tooltip>
            <Typography.Caption>{data.service}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: 'Recruiter',
      dataIndex: 'recruiter',
      rowKey: 'recruiter',
      width: 160,
      sorter: true,
      className: 'active-candidates__recruiter',
      render: data => (
        <Tooltip label={data.full_name}>
          <Typography.Text>{data.full_name}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: 'CV links & documents',
      dataIndex: 'cvs',
      rowKey: 'cvs',
      width: 160,
      className: 'active-candidates__cvs',
      render: (data, { uuid }) => (
        <>
          {data.map(
            (file, idx) =>
              (expanded[uuid] || idx < 2) && !!file.file && <File key={file.file} file={file} size="small" />
          )}
          {data.length > 2 && (
            <Button type={BUTTON_TYPES.TEXT} onClick={() => setExpanded(d => ({ ...d, [uuid]: !d[uuid] }))}>
              {expanded[uuid] ? 'Show less' : 'Show more'}
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status_step',
      rowKey: 'status_step',
      width: 210,
      ellipsis: true,
      className: 'active-candidates__status',
      render: ({ step, status }) =>
        step ? (
          <>
            <Status type={getJobStatusColor(status)}>{status}</Status>
            <Typography.Caption>{step}</Typography.Caption>
          </>
        ) : (
          <Typography.Text weight={TYPOGRAPHY_WEIGHT.BOLD}>-</Typography.Text>
        ),
    },
    {
      title: 'Required action',
      dataIndex: 'action',
      width: 210,
      rowKey: 'action',
      fixed: 'right',
      hideMobileTitle: true,
      ellipsis: true,
      className: 'active-candidates__action',
      render: ({ text, subtext, time, link, button, recruiter_uuid }, record) => (
        <>
          {text && <Typography.Text className="text">{text}</Typography.Text>}
          {subtext && <Typography.Text className="sub-text">{subtext}</Typography.Text>}
          {time && <Typography.Caption className="time">{time}</Typography.Caption>}
          {link && (
            <a className="link" href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          )}
          {button && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={recruiter_uuid && userUuid !== recruiter_uuid}
              onClick={onAction(record)}
            >
              {button.label}
            </Button>
          )}
        </>
      ),
    },
  ];
  return leadDashboard ? columns : [...columns.slice(0, 3), ...columns.slice(4)];
};

export const CANDIDATES_TABLE_COLUMNS = {
  [CANDIDATES_GROUP[0].value]: [COLUMNS_CANDIDATES],
  [CANDIDATES_GROUP[1].value]: [COLUMNS_CANDIDATES_ACTION_TOP, COLUMNS_CANDIDATES_ACTION],
  [CANDIDATES_GROUP[2].value]: [COLUMNS_CANDIDATES_JOB_TOP, COLUMNS_CANDIDATES_JOB],
  [CANDIDATES_GROUP[3].value]: [COLUMNS_CANDIDATES_STEP_TOP, COLUMNS_CANDIDATES_STEP],
};

export const INITIAL_VALUES_1 = {
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const INITIAL_VALUES_2 = {
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
};

export const INITIAL_VALUES = {
  sorting: { key: 'candidate', order: 'asc', field: 'candidate' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
  only_with_actions: false,
};

export const BUTTON_LABELS = {
  [stepsStatuses.waitForResolution]: 'Submit Decision',
  [stepsStatuses.waitingForFinalDecision]: 'Submit Final Decision',
  [stepsStatuses.provideFeedback]: 'Provide Feedback',
  [stepsStatuses.scheduleInterview]: 'Schedule Interview',
  [stepsStatuses.sendOffer]: 'Add Offer',
  [stepsStatuses.receiveFeedback]: 'Submit Decision',
};
