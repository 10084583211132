import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Divider } from 'antd';
import { Button, BUTTON_TYPES, Popup, TYPOGRAPHY_WEIGHT } from '_atoms';
import { Typography } from '_atoms/Typography';
import { POPUP_LABELS } from 'constants/text';
import { ReactComponent as Email } from 'dist/icons/socialNetworks/email.svg';
import { ReactComponent as Phone } from 'dist/icons/socialNetworks/phone.svg';
import { ReactComponent as Skype } from 'dist/icons/socialNetworks/skype.svg';
import { getDuplicatedData } from './utils';
import './style.scss';

export const PopupCandidateDuplicate = ({ open, onClose, duplicate, candidateDuplicates, loading, isMergeAble }) => (
  <Popup
    className="candidate-duplicate-popup"
    footer={null}
    open={open}
    title={POPUP_LABELS.LIST_DUPLICATES}
    onCancel={onClose}
    loading={loading}
  >
    {candidateDuplicates.map((v, idx) => (
      <React.Fragment key={v.uuid}>
        {!!idx && <Divider />}
        <div key={v.uuid} className="duplicate-item">
          <div className="duplicate-item__header">
            <div className="duplicate-item__header__full-name">
              <Typography.Text
                weight={TYPOGRAPHY_WEIGHT.BOLD}
                className={cn({ duplicate: getDuplicatedData(v.full_name_latin, 'full_name_latin', duplicate) })}
              >
                {v.full_name_latin}
              </Typography.Text>
              <Typography.Caption
                className={cn({ duplicate: getDuplicatedData(v.full_name_local, 'full_name_local', duplicate) })}
              >
                {v.full_name_local}
              </Typography.Caption>
            </div>
            {!isMergeAble && (
              <Link
                className="info-wrapper-link"
                to={`/candidates/${v.uuid}/show`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>go to candidate</Button>
              </Link>
            )}
          </div>
          <ul className="duplicate-item__content">
            {v.phone.map(p => (
              <a key={p} href={`tel:${p}`} target="_blank" rel="noopener noreferrer">
                <li className={cn({ duplicate: getDuplicatedData(p, 'phone', duplicate) })}>
                  <Phone />
                  {p}
                </li>
              </a>
            ))}
            {v.email.map(e => (
              <a key={e} href={`mailto:${e}`} target="_blank" rel="noopener noreferrer">
                <li className={cn({ duplicate: getDuplicatedData(e, 'email', duplicate) })}>
                  <Email />
                  {e}
                </li>
              </a>
            ))}
            {v.skype.map(s => (
              <a key={s} href={`skype:${s}?chat`} target="_blank" rel="noopener noreferrer">
                <li className={cn({ duplicate: getDuplicatedData(s, 'skype', duplicate) })}>
                  <Skype />
                  {s}
                </li>
              </a>
            ))}
          </ul>
          {isMergeAble && (
            <div className="duplicate-item__buttons">
              <Link
                className="info-wrapper-link"
                to={`/self-submitted-candidates/merge-profile/${v.uuid}/${duplicate?.uuid}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClose}
              >
                <Button>merge profile</Button>
              </Link>
              <Link
                className="info-wrapper-link"
                to={`/candidates/${v.uuid}/show`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type={BUTTON_TYPES.PRIMARY}>go to candidate</Button>
              </Link>
            </div>
          )}
        </div>
      </React.Fragment>
    ))}
  </Popup>
);
