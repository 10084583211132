import React, { useEffect, useRef, useState } from 'react';
import { Radio } from 'antd';
import { Checkbox, Input, Select, Typography } from '_atoms';
import { ReactComponent as Edit } from 'dist/icons/edit.svg';
import { ReactComponent as Expand } from 'dist/icons/expand.svg';
import { ReactComponent as Shrink } from 'dist/icons/shrink.svg';
import cn from 'classnames';
import { CHECKBOX_FIELDS, INLINE_EDIT_FIELDS, INLINE_SELECT_FIELDS, SELECT_OPTIONS } from './constants';

export const isFieldSelected = (value, selectedValues, field) =>
  value.every(obj2 =>
    selectedValues[field].some(obj1 => {
      if (field === 'sources') {
        const obj_1 = { ...obj1 };
        const obj_2 = { ...obj2 };
        delete obj_1.is_main;
        delete obj_2.is_main;
        return JSON.stringify(obj_1) === JSON.stringify(obj_2);
      }
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    })
  );

const isFieldsEqual = (field, data1, data2) => {
  const value1 = data1[field];
  const value2 = data2[field];
  switch (field) {
    case 'cvs':
      return JSON.stringify(value1.map(v => v.filename).sort()) === JSON.stringify(value2.map(v => v.filename).sort());
    case 'country':
      return value1 === value2 && data1.city === data2.city;
    default:
      return JSON.stringify(value1) === JSON.stringify(value2);
  }
};

const isFieldHasData = value =>
  value !== undefined && value !== null && (typeof value === 'boolean' || !!value?.length);

const ExpandableBlock = ({ children, opened, toggle }) => {
  const contentRef = useRef(null);

  const [needsExpand, setNeedExpand] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNeedExpand(contentRef.current?.scrollHeight > 64);
    }, 10);
  }, [children]);

  return (
    <>
      <div ref={contentRef} className={cn('expandable-content', { opened })}>
        {children}
      </div>
      {needsExpand &&
        (opened ? (
          <Shrink className="expand-button" onClick={toggle} />
        ) : (
          <Expand className="expand-button" onClick={toggle} />
        ))}
    </>
  );
};

export const useColumns = (
  selectedValues,
  handleChange,
  activeCheckboxes,
  handleCheckboxChange,
  editing,
  handleEditClick,
  handleInputBlur,
  expandedRows,
  toggleExpand
) => [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
    render: (title, { isHeader, required }) => (
      <span className="title-data">
        {isHeader ? (
          <span className="header-cell">{title}</span>
        ) : (
          <>
            {title} {required && <span className="required">*</span>}
          </>
        )}
      </span>
    ),
  },
  {
    title: 'Current profile',
    dataIndex: 'currentCandidate',
    key: 'currentCandidate',
    className: 'with-border',
    render: (value, { render, ...record }) =>
      record.isHeader ? (
        <span className="header-cell" />
      ) : isFieldHasData(value) ? (
        <ExpandableBlock opened={expandedRows[record.field]} toggle={toggleExpand(record.field)}>
          {isFieldsEqual(record.field, record.currentCandidateData, record.existingCandidateData) ||
          !isFieldHasData(record.existingCandidate) ? (
            <span className="disabled">{render(value, record.currentCandidateData, record.field)}</span>
          ) : CHECKBOX_FIELDS.includes(record.field) ? (
            <Checkbox
              id={`current_${record.field}`}
              label={render(value, record.currentCandidateData, record.field)}
              isSelected={activeCheckboxes[record.field].current}
              onChange={({ target }) => handleCheckboxChange(record.field, true, target)}
            />
          ) : (
            <Radio.Group
              value={record.field === 'country' ? selectedValues.location : selectedValues[record.field]}
              onChange={e => handleChange(record.field, e.target.value, true)}
            >
              <Radio value={record.field === 'country' ? record.currentCandidateData.location : value}>
                <Typography.Text>{render(value, record.currentCandidateData, record.field)}</Typography.Text>
              </Radio>
            </Radio.Group>
          )}
        </ExpandableBlock>
      ) : (
        <span className="disabled">—</span>
      ),
  },
  {
    title: 'Existing profile',
    dataIndex: 'existingCandidate',
    key: 'existingCandidate',
    className: 'with-border',
    render: (value, { render, ...record }) =>
      record.isHeader ? (
        <span className="header-cell" />
      ) : isFieldHasData(value) ? (
        <ExpandableBlock opened={expandedRows[record.field]} toggle={toggleExpand(record.field)}>
          {isFieldsEqual(record.field, record.existingCandidateData, record.currentCandidateData) ||
          !isFieldHasData(record.currentCandidate) ? (
            <span className="disabled">{render(value, record.existingCandidateData, record.field)}</span>
          ) : CHECKBOX_FIELDS.includes(record.field) ? (
            <Checkbox
              id={`existing_${record.field}`}
              label={render(value, record.existingCandidateData, record.field)}
              isSelected={activeCheckboxes[record.field].existing}
              onChange={({ target }) => handleCheckboxChange(record.field, false, target)}
            />
          ) : (
            <Radio.Group
              value={record.field === 'country' ? selectedValues.location : selectedValues[record.field]}
              onChange={e => handleChange(record.field, e.target.value, false)}
            >
              <Radio value={record.field === 'country' ? record.existingCandidateData.location : value}>
                <Typography.Text>{render(value, record.existingCandidateData, record.field)}</Typography.Text>
              </Radio>
            </Radio.Group>
          )}
        </ExpandableBlock>
      ) : (
        <span className="disabled">—</span>
      ),
  },
  {
    title: 'Merged profile',
    dataIndex: 'selected',
    key: 'selected',
    className: 'with-border merged-info',
    render: (value, { render, ...record }) =>
      record.isHeader ? (
        <span className="header-cell" />
      ) : INLINE_EDIT_FIELDS.includes(record.field) && editing[record.field] ? (
        INLINE_SELECT_FIELDS.includes(record.field) ? (
          <Select
            value={selectedValues[record.field]}
            onChange={v => handleChange(record.field, v.value)}
            onBlur={() => handleInputBlur()}
            options={SELECT_OPTIONS[record.field]}
            autoFocus
            open
          />
        ) : (
          <Input
            value={selectedValues[record.field]}
            onChange={e => handleChange(record.field, e.target.value)}
            onBlur={e => handleInputBlur(record.field, e.target.value)}
            autoFocus
          />
        )
      ) : (
        <>
          <ExpandableBlock opened={expandedRows[record.field]} toggle={toggleExpand(record.field)}>
            <Typography.Text>
              {isFieldHasData(value) ? render(value, record.selectedData, record.field) : '—'}
            </Typography.Text>
          </ExpandableBlock>
          <Edit className="edit-button" onClick={() => handleEditClick(record.field)} />
        </>
      ),
  },
];
