import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { RichText, Typography } from '_atoms';
import { TIME_ZONE } from 'constants/constants';

export const FIELDS = [
  {
    field: '_profile',
    title: 'Profile',
    required: false,
    isHeader: true,
    render: value => value,
  },
  {
    field: 'first_name_latin',
    title: 'First name (Latin)',
    required: true,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'last_name_latin',
    title: 'Last name (Latin)',
    required: true,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'first_name_local',
    title: 'First name (Local)',
    required: false,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'last_name_local',
    title: 'Last name (Local)',
    required: false,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'country',
    title: 'Country/City',
    required: true,
    isHeader: false,
    render: (value, record) =>
      `${record.city ? `${record.city}, ` : ''}${
        record.state && record.state !== 'Default state' ? `${record.state}, ` : ''
      }${record.country || '—'}`,
  },
  {
    field: 'offices',
    title: 'Office location',
    required: false,
    isHeader: false,
    render: value => value.map(l => <div key={l.uuid}>{l.name}</div>),
  },
  {
    field: 'timezone',
    title: 'Time zone',
    required: false,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'foreign_languages',
    title: 'Foreign language',
    required: false,
    isHeader: false,
    render: value =>
      value.map(l => (
        <div key={l.language + l.level}>
          {l.language}: {l.level}
        </div>
      )),
  },
  {
    field: 'sources',
    title: 'Sources',
    required: true,
    isHeader: false,
    render: value =>
      value.map(s => (
        <div key={s.source}>
          {s.additional_source_name ? (
            <>
              <span style={{ color: '#717C8C' }}>
                {String(s.source).charAt(0).toUpperCase() + String(s.source).slice(1)}:{' '}
              </span>
              {s.additional_source_name}
            </>
          ) : (
            s.source
          )}
          {value.length > 1 && s.is_main && <strong> (Main)</strong>}
        </div>
      )),
  },
  {
    field: 'is_ready_to_relocate',
    title: 'Ready to relocate',
    required: false,
    isHeader: false,
    render: value => (value ? 'Yes' : 'No'),
  },
  {
    field: 'is_ready_to_business_trips',
    title: 'Ready for business trips',
    required: false,
    isHeader: false,
    render: value => (value ? 'Yes' : 'No'),
  },
  {
    field: '_information',
    title: 'Contact information',
    required: false,
    isHeader: true,
    render: value => value,
  },
  {
    field: 'phone',
    title: 'Phone number',
    required: false,
    isHeader: false,
    render: value => value.map(p => <div key={p}>{p}</div>),
  },
  {
    field: 'email',
    title: 'Email address',
    required: false,
    isHeader: false,
    render: value => value.map(e => <div key={e}>{e}</div>),
  },
  {
    field: 'telegram',
    title: 'Telegram',
    required: false,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'skype',
    title: 'Skype',
    required: false,
    isHeader: false,
    render: value => value,
  },
  {
    field: 'social_networks',
    title: 'Links',
    required: false,
    isHeader: false,
    render: value =>
      value.map((l, ids) => (
        <div key={l.link + ids}>
          {l.link}: {l.network}
        </div>
      )),
  },
  {
    field: '_experience',
    title: 'Experience',
    required: false,
    isHeader: true,
    render: value => value,
  },
  {
    field: 'specialities',
    title: 'Specialty',
    required: true,
    isHeader: false,
    render: value =>
      value.map(s => (
        <div style={{ whiteSpace: 'nowrap' }}>
          {s.level} {s.speciality}
        </div>
      )),
  },
  {
    field: 'cvs',
    title: 'Documents',
    required: false,
    isHeader: false,
    render: value => value.map(file => <div key={file.file}>{file.filename}</div>),
  },
  {
    field: 'cvs_links',
    title: 'CV links',
    required: false,
    isHeader: false,
    render: value => value.map(l => <div key={l}>{l}</div>),
  },
  {
    field: 'tags',
    title: 'Tags',
    required: false,
    isHeader: false,
    render: value => value.join(', '),
  },
  {
    field: 'description',
    title: 'Description',
    required: false,
    isHeader: false,
    render: (value, _, __, expandedRows, toggleExpand) => (
      <div>
        <RichText text={value} opened />
      </div>
    ),
  },
  {
    field: '_working_experience',
    title: 'Working experience',
    required: false,
    isHeader: true,
    render: value => value,
  },
  {
    field: 'experience',
    title: 'Working experience',
    required: false,
    render: value =>
      value.map((exp, idx) => (
        <div key={exp.position + idx} className={cn({ 'job-experience__sub-block': idx !== value.length - 1 })}>
          <Typography.Title4>{exp.company}</Typography.Title4>
          <Typography.Caption>
            {`${exp.worked_from ? moment(exp.worked_from).format('MM.YYYY') : 'no date'} - ${
              exp.is_current ? 'current' : exp.worked_till ? moment(exp.worked_till).format('MM.YYYY') : 'no date'
            } | ${exp.position}`}
          </Typography.Caption>
          <RichText text={exp.additional_info} opened />
        </div>
      )),
  },
];

export const INLINE_EDIT_FIELDS = [
  FIELDS[1].field,
  FIELDS[2].field,
  FIELDS[3].field,
  FIELDS[4].field,
  FIELDS[7].field,
  FIELDS[10].field,
  FIELDS[11].field,
  FIELDS[15].field,
  FIELDS[16].field,
];

export const INLINE_SELECT_FIELDS = [FIELDS[7].field, FIELDS[10].field, FIELDS[11].field];

export const CHECKBOX_FIELDS = [FIELDS[9].field, FIELDS[13].field, FIELDS[14].field, FIELDS[20].field];

export const SELECT_OPTIONS = {
  timezone: TIME_ZONE,
  is_ready_to_relocate: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  is_ready_to_business_trips: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
};
