import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextArea, Popup } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import history from 'helpers/history';
import { BUTTON_TEXT } from 'constants/text';
import { candidateBlacklistAPI, getCandidateInfo, getTimeline, setCandidateBlacklist } from 'actions';
import './style.scss';

export const PopupBlacklist = ({ open, onClose, candidate, isSSC }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [comment, setComment] = useState('');
  const onChangeComment = e => setComment(e.target.value);

  const onClosePopup = () => {
    setComment('');
    onClose();
  };

  const onBlackList = () => {
    if (isSSC) {
      candidateBlacklistAPI(id, { blacklist_comment: comment }).then(() => {
        history.push(`/candidates/${candidate.uuid}/show`);
        onClose();
      });
    } else {
      setCandidateBlacklist(candidate.uuid, { blacklist_comment: comment }).then(() => {
        dispatch(getCandidateInfo(id));
        dispatch(getTimeline(id, undefined, true));
        onClose();
      });
    }
  };

  return (
    <Popup
      open={open}
      title="Move candidate to blacklist?"
      className="candidate-blacklist"
      cancelButtonProps={{
        children: BUTTON_TEXT.CANCEL,
        onClick: onClosePopup,
      }}
      okButtonProps={{
        children: BUTTON_TEXT.BLACKLIST,
        disabled: !comment,
        onClick: onBlackList,
      }}
    >
      <Typography.Text className="candidate-blacklist__name-latin" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {candidate.first_name_latin} {candidate.last_name_latin}
      </Typography.Text>
      <Typography.Caption className="candidate-blacklist__name-local">
        {candidate.first_name_local} {candidate.last_name_local}
      </Typography.Caption>
      <Typography.Text className="candidate-blacklist__warning">
        If you move this candidate to blacklist, you will not be able to undo this action later. Move candidate to
        blacklist?
      </Typography.Text>
      <TextArea label="Comment" value={comment} placeholder="Add a comment" onChange={onChangeComment} />
    </Popup>
  );
};
