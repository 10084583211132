import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import history from 'helpers/history';
import { Input, notification, NOTIFICATION_TYPES, Popup, RichArea, Select, Table, Upload } from '_atoms';
import {
  ExperienceList,
  FormCreateEdit,
  FormListDoubleInputSelect,
  FormListDoubleSelect,
  FormListSingle,
  FormListSources,
} from '_molecules';
import * as actions from 'actions';
import { getLanguages, getLevels, getLocations, getPositions } from 'store/selectors/commonSelectors';
import {
  getCandidateCompanies,
  getCandidateCountries,
  getCandidateSpecialities,
  getCandidateTags,
} from 'store/selectors/candidateSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import { levels } from 'constants/constants';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { SELECT_TYPES } from '_atoms/Select/constants';
import {
  EMAIL_VALIDATION,
  EXPERIENCE_LEVEL_OPTIONS,
  PHONE_VALIDATOR,
  SOCIAL_NETWORKS_OPTIONS,
  VALIDATION_MAX_100,
} from '_organisms/CandidateCreationForm/constants';
import { REQUIRED_RULE } from '_organisms/InterviewFeedback/constants';
import { detectNetwork } from '_organisms/CandidateCreationForm/utyls';
import { validatorL } from '_templates/CreateOpening/utils';
import { uploadDocs } from '_templates/CandidateCreation/utils';
import { CHECKBOX_FIELDS, FIELDS, INLINE_EDIT_FIELDS } from './constants';
import { isFieldSelected, useColumns } from './utils';
import './style.scss';

const INITIAL_VALUES = {
  offices: [],
  sources: [],
  foreign_languages: [],
  phone: [],
  email: [],
  telegram: [],
  skype: [],
  social_networks: [],
  specialities: [],
  cvs: [],
  cvs_links: [],
  tags: [],
  experience: [],
};

export const MergeProfile = () => {
  const { id, duplicate_id } = useParams();
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const [currentCandidate, setCurrentCandidate] = useState(INITIAL_VALUES);
  const [existingCandidate, setExistingCandidate] = useState(INITIAL_VALUES);
  const [selectedValues, setSelectedValues] = useState(INITIAL_VALUES);
  const [loadingCandidate, setLoadingCandidate] = useState(true);
  const [loadingSSCCandidate, setLoadingSSCCandidate] = useState(true);
  const [editing, setEditing] = useState({});
  const [modalField, setModalField] = useState('');
  const [isEdited, setIsEdited] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [mergeCheckPopup, setMergeCheckPopup] = useState(false);
  const [error, setError] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [activeCheckboxes, setActiveCheckboxes] = useState({
    sources: { current: true, existing: true },
    phone: { current: true, existing: true },
    email: { current: true, existing: true },
    cvs: { current: true, existing: true },
  });

  const toggleExpand = key => () => {
    setExpandedRows(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCheckboxChange = (field, isCurrent, { checked }) => {
    setIsEdited(true);
    const value = isCurrent ? currentCandidate[field] : existingCandidate[field];
    const secondCheckboxValue = isCurrent ? existingCandidate[field] : currentCandidate[field];
    const secondCheckboxState = document.getElementById(isCurrent ? `existing_${field}` : `current_${field}`).checked;
    const selectedValue = [];
    setActiveCheckboxes(c => ({
      ...c,
      [field]: { current: isCurrent ? checked : c[field].current, existing: isCurrent ? c[field].existing : checked },
    }));
    if (checked) {
      value.forEach(val => {
        selectedValue.push(val);
      });
    }
    if (secondCheckboxState) {
      secondCheckboxValue.forEach(val => {
        if (field === 'cvs') {
          if (!selectedValue.find(v => v.filename === val.filename)) selectedValue.push(val);
        } else if (field === 'sources') {
          if (!selectedValue.find(v => v.source + v.additional_source_name === val.source + val.additional_source_name))
            selectedValue.push({ ...val, is_main: checked ? false : val.is_main });
        } else if (!selectedValue.includes(val)) {
          selectedValue.push(val);
        }
      });
    }
    setSelectedValues(prev => ({ ...prev, [field]: selectedValue }));
  };

  const handleChange = (key, value, isCurrent) => {
    setIsEdited(true);
    if (key === 'country') {
      const country = isCurrent ? currentCandidate.country : existingCandidate.country;
      const state = isCurrent ? currentCandidate.state : existingCandidate.state;
      const city = isCurrent ? currentCandidate.city : existingCandidate.city;
      setSelectedValues(prev => ({
        ...prev,
        country,
        state,
        city,
        location: `${city}${state}${country}`,
      }));
    } else {
      setSelectedValues(prev => ({ ...prev, [key]: value }));
    }
  };

  const handleEditClick = key => {
    setIsEdited(true);
    if (INLINE_EDIT_FIELDS.includes(key)) {
      setEditing(prev => ({ ...prev, [key]: true }));
    } else {
      setModalField(key);
    }
  };

  const handleInputBlur = (key, value) => {
    if (key) {
      setSelectedValues(prev => {
        const newValues = { ...prev, [key]: value };
        checkAndActivateSelection(key, newValues[key]);
        return newValues;
      });
      setEditing(prev => ({ ...prev, [key]: false }));
    } else {
      setEditing(prev => Object.fromEntries(Object.keys(prev).map(k => [k, false])));
    }
  };

  const checkAndActivateSelection = (key, value) => {
    if (value === currentCandidate[key]) {
      setSelectedValues(prev => ({ ...prev, [key]: currentCandidate[key] }));
    } else if (value === existingCandidate[key]) {
      setSelectedValues(prev => ({ ...prev, [key]: existingCandidate[key] }));
    }
  };

  const data = useMemo(
    () =>
      FIELDS.map(({ field, ...fieldInfo }) => ({
        ...fieldInfo,
        field,
        currentCandidate: currentCandidate[field],
        currentCandidateData: currentCandidate,
        existingCandidate: existingCandidate[field],
        existingCandidateData: existingCandidate,
        selected: selectedValues[field],
        selectedData: selectedValues,
      })),
    [currentCandidate, existingCandidate, selectedValues]
  );

  const columns = useColumns(
    selectedValues,
    handleChange,
    activeCheckboxes,
    handleCheckboxChange,
    editing,
    handleEditClick,
    handleInputBlur,
    expandedRows,
    toggleExpand
  );

  const onMergeValidate = () => {
    if (
      selectedValues.first_name_latin &&
      selectedValues.last_name_latin &&
      selectedValues.country &&
      selectedValues.sources.length &&
      selectedValues.specialities.length
    ) {
      setError(false);
      setMergeCheckPopup(true);
    } else {
      setError(true);
    }
  };

  const onUpdateValues = popupData => {
    if (CHECKBOX_FIELDS.includes(modalField)) {
      setActiveCheckboxes(c => ({
        ...c,
        [modalField]: {
          current: isFieldSelected(currentCandidate[modalField], popupData, modalField),
          existing: isFieldSelected(existingCandidate[modalField], popupData, modalField),
        },
      }));
    }
    setSelectedValues(d => ({ ...d, ...popupData }));
    setModalField('');
  };

  const onMerge = async () => {
    setMergeCheckPopup(false);
    const body = { ...selectedValues };
    body.cvs = [
      ...selectedValues.cvs.filter(cv => cv.uuid).map(cv => cv.uuid),
      ...(await uploadDocs(selectedValues.cvs.filter(cv => !cv.uuid))),
    ];
    body.skype = [selectedValues.skype || ''];
    body.telegram = [selectedValues.telegram || ''];
    body.offices = selectedValues.offices.map(o => o.uuid);
    actions.setMergeProfilesAPI(id, body).then(() => {
      actions.deleteCandidate(duplicate_id, true).then(() => {
        setMergeCheckPopup(false);
        history.push(`/candidates/${id}/show`);
        notification({ message: 'Profiles successfully merged', type: NOTIFICATION_TYPES.SUCCESS });
      });
    });
  };

  const onCancelMerge = () => {
    if (isEdited) {
      setDiscardPopup(true);
    } else {
      history.push(`/dashboard/${user.active_role.name.toLowerCase().replace(/\s/g, '_')}?tab=ssc`);
    }
  };

  useEffect(() => {
    dispatch(actions.getCandidateInfo(duplicate_id, true, false, false))
      .then(d => {
        setCurrentCandidate({
          ...d,
          telegram: d.telegram[0],
          skype: d.skype[0],
          cvs: d.cvs.map(c => ({ filename: c })),
          location: `${d.city}${d.state}${d.country}`,
        });
        setSelectedValues({
          ...d,
          telegram: d.telegram[0],
          skype: d.skype[0],
          cvs: d.cvs.map(c => ({ filename: c })),
          location: `${d.city}${d.state}${d.country}`,
        });
        if (d.cvs.length) {
          actions.getDocumentsByUUID(d.cvs).then(cvs => {
            setCurrentCandidate(c => ({ ...c, cvs }));
            setSelectedValues(c => ({ ...c, cvs }));
          });
        }
        dispatch(actions.getCandidateInfo(id, false, false, false))
          .then(d => {
            setExistingCandidate({
              ...d,
              telegram: d.telegram[0],
              skype: d.skype[0],
              cvs: d.cvs.map(c => ({ filename: c })),
              location: `${d.city}${d.state}${d.country}`,
            });
            setSelectedValues(c => ({
              ...Object.keys(c).reduce((acc, key) => {
                acc[key] =
                  c[key] !== undefined && c[key] !== null && (typeof c[key] === 'boolean' || !!c[key]?.length)
                    ? c[key]
                    : d[key];
                return acc;
              }, {}),
              sources: [
                ...Object.values(
                  [...c.sources, ...d.sources.map(s => ({ ...s, is_main: false }))].reduce((acc, obj) => {
                    const key = obj.source + obj.additional_source_name;
                    if (!acc[key]) acc[key] = obj;
                    return acc;
                  }, {})
                ),
              ],
              phone: [...new Set([...c.phone, ...d.phone])],
              email: [...new Set([...c.email, ...d.email])],
            }));
            actions.getDocumentsByUUID(d.cvs).then(cvs => {
              if (d.cvs.length) {
                setExistingCandidate(c => ({ ...c, cvs }));
                setSelectedValues(c => ({
                  ...c,
                  cvs: [...new Map([...c.cvs, ...cvs].map(obj => [obj.filename, obj])).values()],
                }));
              }
            });
          })
          .catch(() => {
            setExistingCandidate(INITIAL_VALUES);
            setSelectedValues(INITIAL_VALUES);
          })
          .finally(() => {
            setLoadingCandidate(false);
          });
      })
      .catch(() => {
        setCurrentCandidate(INITIAL_VALUES);
      })
      .finally(() => {
        setLoadingSSCCandidate(false);
      });

    dispatch(actions.getForeignLanguages());
    dispatch(actions.getCountries());
    dispatch(actions.getCompanies({}));
    dispatch(actions.getListLocations());
    dispatch(actions.getPositions());
    dispatch(actions.getTags());
    dispatch(actions.getSpecialties());
    dispatch(actions.getCandidateSourcesAPI());
  }, []);

  return (
    <div className="wrapper-content candidates-merge-page">
      <FormCreateEdit
        title="Merge profiles"
        options={[]}
        onOk={onMergeValidate}
        okText="Merge profiles"
        onCancel={onCancelMerge}
        cancelText="Cancel"
      >
        <Table
          error={error ? 'Please fill in all required fields' : ''}
          cardTitle="Data comparison"
          rowKey="field"
          columns={columns}
          data={data}
          loading={loadingCandidate || loadingSSCCandidate}
          showCardFooter={false}
        />
      </FormCreateEdit>
      <PopupAddCountry
        open={modalField === 'country'}
        onClose={() => setModalField('')}
        initialValues={{
          country: selectedValues.country
            ? { value: selectedValues.country, label: selectedValues.country }
            : undefined,
          state: { value: selectedValues.state, label: selectedValues.state },
          city: selectedValues.city ? { value: selectedValues.city, label: selectedValues.city } : undefined,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddOffices
        open={modalField === 'offices'}
        onClose={() => setModalField('')}
        initialValues={{
          offices: selectedValues.offices.map(o => ({
            value: o.uuid,
            label: o.name,
          })),
        }}
        onSave={onUpdateValues}
      />
      <PopupAddSources
        open={modalField === 'sources'}
        onClose={() => setModalField('')}
        initialValues={{
          sources: selectedValues.sources,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddLanguages
        open={modalField === 'foreign_languages'}
        onClose={() => setModalField('')}
        initialValues={{
          foreign_languages: selectedValues.foreign_languages.length
            ? selectedValues.foreign_languages.map(lang => ({
                language: { value: lang.language, label: lang.language },
                level: { value: lang.level, label: lang.level },
              }))
            : [{ language: undefined, level: undefined }],
        }}
        onSave={onUpdateValues}
      />
      <PopupAddPhone
        open={modalField === 'phone'}
        onClose={() => setModalField('')}
        initialValues={{
          phone: selectedValues.phone,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddEmail
        open={modalField === 'email'}
        onClose={() => setModalField('')}
        initialValues={{
          email: selectedValues.email,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddLinks
        open={modalField === 'social_networks'}
        onClose={() => setModalField('')}
        initialValues={{
          social_networks: selectedValues.social_networks,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddSpecialty
        open={modalField === 'specialities'}
        onClose={() => setModalField('')}
        initialValues={{
          specialities: selectedValues.specialities.length
            ? selectedValues.specialities.map(s => ({
                speciality: s.speciality ? [{ value: s.speciality, label: s.speciality }] : undefined,
                level: { value: s.level, label: s.level },
              }))
            : [{ speciality: undefined, level: { value: levels[0], label: levels[0] } }],
        }}
        onSave={onUpdateValues}
      />
      <PopupAddDocuments
        open={modalField === 'cvs'}
        onClose={() => setModalField('')}
        initialValues={{
          cvs: selectedValues.cvs.map(d => ({ ...d, uid: d.uuid, name: d.filename })),
        }}
        onSave={onUpdateValues}
      />
      <PopupAddCVLinks
        open={modalField === 'cvs_links'}
        onClose={() => setModalField('')}
        initialValues={{
          cv_link_0: selectedValues.cvs_links[0],
          cv_link_1: selectedValues.cvs_links[1],
        }}
        onSave={onUpdateValues}
      />
      <PopupAddDescription
        open={modalField === 'description'}
        onClose={() => setModalField('')}
        initialValues={{
          description: selectedValues.description,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddTags
        open={modalField === 'tags'}
        onClose={() => setModalField('')}
        initialValues={{
          tags: selectedValues.tags,
        }}
        onSave={onUpdateValues}
      />
      <PopupAddWorkingExperience
        open={modalField === 'experience'}
        onClose={() => setModalField('')}
        initialValues={{
          experience: selectedValues.experience.length
            ? selectedValues.experience.map(ex => ({
                company: ex.company ? [{ value: ex.company, label: ex.company }] : [],
                position: ex.position ? [{ value: ex.position, label: ex.position }] : [],
                worked_from: ex.worked_from ? moment(ex.worked_from, 'YYYY-MM-DD') : null,
                worked_till: ex.worked_till ? moment(ex.worked_till, 'YYYY-MM-DD') : null,
                is_current: ex.is_current,
                additional_info: ex.additional_info,
                additional_info_initial: ex.additional_info,
              }))
            : [
                {
                  company: undefined,
                  position: undefined,
                  worked_from: moment(),
                  worked_till: moment(),
                  is_current: false,
                  additional_info: '',
                  additional_info_initial: '',
                },
              ],
        }}
        onSave={onUpdateValues}
      />
      <Popup
        open={mergeCheckPopup}
        title="Merge profiles"
        cancelButtonProps={{ children: 'Cancel', onClick: () => setMergeCheckPopup(false) }}
        okButtonProps={{ children: 'Merge Profiles', onClick: onMerge }}
      >
        <div>Are you sure you want to merge these candidate profiles?</div>
        <div>ㅤ</div>
        <div>Once confirmed, you will not be able to cancel this action.</div>
      </Popup>
      <Popup
        open={discardPopup}
        title={POPUP_LABELS.DISCARD_CHANGES}
        cancelButtonProps={{ onClick: () => setDiscardPopup(false), children: BUTTON_TEXT.BACK_TO_PAGE }}
        okButtonProps={{
          onClick: () => history.push(`/dashboard/${user.active_role.name.toLowerCase().replace(/\s/g, '_')}?tab=ssc`),
          children: BUTTON_TEXT.DISCARD,
        }}
      >
        {POPUP_BODY.CHANGES_NOT_SAVED}
      </Popup>
    </div>
  );
};

const PopupAddCountry = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();
  const countries = useSelector(getCandidateCountries);
  const countriesOptions = useMemo(
    () =>
      countries.map(el => ({
        states: el.states.map(s => ({
          value: s.name,
          label: s.name,
          cities: s.cities.map(c => ({ value: c, label: c })),
        })),
        value: el.name,
        label: el.name,
      })),
    [countries]
  );

  const [openCity, setOpenCity] = useState(false);

  const onFinish = data => {
    onSave({
      country: data.country?.value,
      state: data.state?.value || 'Default state',
      city: data.city?.[0]?.value,
      location: `${data.city?.[0]?.value}${data.state?.value || 'Default state'}${data.country?.value}`,
    });
  };

  const onValueChange = ({ country, state, city }) => {
    if (country) {
      form.setFieldValue('state', undefined);
      form.setFieldValue('city', []);
    }
    if (state) {
      form.setFieldValue('city', []);
    }
    if (city) {
      form.setFieldValue('city', city.slice(-1));
    }
  };

  const onCountryChange = c => {
    if (!c) {
      form.setFieldValue('state', { value: 'Default state', label: 'Default state' });
    } else if (c.states.length === 1 && c.states[0].value === 'Default state') {
      form.setFieldValue('state', c.states[0]);
    }
  };

  useEffect(() => {
    setTimeout(() => form.resetFields(), 10);
  }, [open]);

  return (
    <Popup
      open={open}
      title="Country/City"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-country',
        htmlType: 'submit',
      }}
    >
      <Form
        name="form-edit-country"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValueChange}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Select
              labelInValue
              label="Country"
              options={countriesOptions}
              itemProps={{ name: 'country' }}
              onChange={onCountryChange}
              onClear={() => {
                form.setFieldValue('state', undefined);
                form.setFieldValue('city', []);
              }}
              allowClear
            />
          </Col>
          <Col span={24}>
            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.country !== curr.country}>
              {({ getFieldValue }) => {
                const options = countriesOptions.find(el => el.value === getFieldValue('country')?.value)?.states || [];
                if (!options.length || (options.length === 1 && options[0].label === 'Default state')) return null;
                return (
                  <Row gutter={[32, 0]}>
                    <Col span={24}>
                      <Select
                        labelInValue
                        label="State"
                        options={options}
                        itemProps={{ name: 'state' }}
                        disabled={!getFieldValue('country')}
                        allowClear
                      />
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => prev.country !== curr.country || prev.state !== curr.state}
            >
              {({ getFieldValue }) => (
                <Select
                  className="single-select-tags"
                  open={openCity}
                  onDropdownVisibleChange={setOpenCity}
                  onChange={() => setOpenCity(false)}
                  labelInValue
                  label="City"
                  options={
                    countriesOptions
                      .find(el => el.value === getFieldValue('country')?.value)
                      ?.states.find(el => el.value === getFieldValue('state')?.value)?.cities || []
                  }
                  itemProps={{ name: 'city' }}
                  disabled={!getFieldValue('state')?.value}
                  maxTagCount={1}
                  mode={SELECT_TYPES.CREATABLE}
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

const PopupAddOffices = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const offices = useSelector(getLocations);
  const officesOptions = useMemo(() => offices.map(el => ({ value: el.uuid, label: el.name })), [offices]);

  const onFinish = data => {
    onSave({
      offices: data.offices.map(o => ({
        uuid: o.value,
        name: o.label,
      })),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Office location"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-offices',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-offices" form={form} initialValues={initialValues} onFinish={onFinish}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Select
              labelInValue
              label="Office location"
              options={officesOptions}
              itemProps={{ name: 'offices' }}
              mode={SELECT_TYPES.MULTIPLE}
              allowClear
            />
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

const PopupAddSources = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = data => {
    onSave(data);
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Sources"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-offices',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-offices" form={form} initialValues={initialValues} onFinish={onFinish}>
        <FormListSources />
      </Form>
    </Popup>
  );
};

const PopupAddLanguages = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const languages = useSelector(getLanguages);
  const languagesOptions = useMemo(() => languages.map(el => ({ value: el, label: el })), [languages]);
  const languageLevels = useSelector(getLevels);
  const languageLevelsOptions = useMemo(() => languageLevels.map(el => ({ value: el, label: el })), [languageLevels]);

  const onFinish = data => {
    onSave({
      foreign_languages: data.foreign_languages
        .filter(el => el.language?.value)
        .map(el => ({ language: el.language.value, level: el.level.value })),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Foreign language"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-languages',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-languages" form={form} initialValues={initialValues} onFinish={onFinish}>
        <FormListDoubleSelect
          listName="foreign_languages"
          buttonText="Add language"
          firstSelectProps={{
            name: 'language',
            label: 'Foreign language',
            options: languagesOptions,
          }}
          secondSelectProps={{
            name: 'level',
            options: languageLevelsOptions,
          }}
          firstSelectValidator={validatorL('level', 'Please select language')}
          secondSelectValidator={validatorL('language', 'Please select language level')}
        />
      </Form>
    </Popup>
  );
};

const PopupAddPhone = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = data => {
    onSave({
      phone: data.phone.filter(el => el),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Phone number"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-phone',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-phone" form={form} initialValues={initialValues} onFinish={onFinish}>
        <FormListSingle
          listName="phone"
          label="Phone number"
          placeholder="Enter phone number"
          buttonText="Add phone number"
          maxCount={3}
          maxLength={16}
          rules={[PHONE_VALIDATOR]}
        />
      </Form>
    </Popup>
  );
};

const PopupAddEmail = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = data => {
    onSave({
      email: data.email.filter(el => el),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Email address"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-email',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-email" form={form} initialValues={initialValues} onFinish={onFinish}>
        <FormListSingle
          listName="email"
          label="Email address"
          placeholder="Enter email address"
          buttonText="Add email"
          maxCount={3}
          rules={[EMAIL_VALIDATION]}
        />
      </Form>
    </Popup>
  );
};

const PopupAddDescription = ({ open, onClose, onSave, initialValues }) => {
  const [description, setDescription] = useState('');

  const onFinish = () => {
    onSave({ description });
    onClose();
  };

  useEffect(() => {
    setDescription(initialValues.description);
  }, [open, initialValues.description]);

  return (
    <Popup
      open={open}
      title="Description"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-description',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-description" initialValues={initialValues} onFinish={onFinish}>
        <RichArea
          label="Description"
          placeholder="Enter description here"
          initialValue={initialValues.description}
          onChange={setDescription}
          maxLength={10000}
        />
      </Form>
    </Popup>
  );
};

const PopupAddLinks = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const onDetectLink = (value, key) => {
    const fields = form.getFieldsValue();
    const { social_networks } = fields;
    const network = detectNetwork(value);
    Object.assign(social_networks[key] || {}, { network });
    form.setFieldsValue({ social_networks });
  };

  const onFinish = data => {
    onSave({
      social_networks: data.social_networks.filter(el => el.link),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Links"
      className="popup-edit-links"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-links',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-links" form={form} initialValues={initialValues} onFinish={onFinish}>
        <FormListDoubleInputSelect
          listName="social_networks"
          label="Links"
          buttonText="Add link"
          firstItemName="link"
          secondItemName="network"
          inputProps={{
            placeholder: 'Please enter link',
            maxLength: 1000,
            onChange: (e, f) => onDetectLink(e.target.value, f.name),
          }}
          options={SOCIAL_NETWORKS_OPTIONS}
          maxCount={5}
        />
      </Form>
    </Popup>
  );
};

const PopupAddSpecialty = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();
  const [openSpeciality, setOpenSpeciality] = useState(false);

  const specialties = useSelector(getCandidateSpecialities);
  const specialtiesOptions = useMemo(() => specialties.map(el => ({ value: el.name, label: el.name })), [specialties]);

  const onFinish = data => {
    onSave({
      specialities: data.specialities.map(s => ({
        speciality: s.speciality?.[0]?.value || null,
        level: s.level.value,
      })),
    });
    onClose();
  };

  const onValueChange = ({ specialities: spec }, { specialities }) => {
    if (spec) {
      form.setFieldValue(
        'specialities',
        specialities.map(s =>
          s
            ? { level: s.level, speciality: s.speciality.slice(-1) }
            : { speciality: undefined, level: { value: levels[0], label: levels[0] } }
        )
      );
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Specialty"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-specialty',
        htmlType: 'submit',
      }}
    >
      <Form
        name="form-edit-specialty"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValueChange}
      >
        <FormListDoubleSelect
          listName="specialities"
          buttonText="Add Specialty"
          firstSelectValidator={validatorL('level', 'Please select specialty', 'specialities')}
          secondSelectValidator={validatorL('speciality', 'Please select specialty', 'specialities')}
          firstSelectProps={{
            name: 'speciality',
            label: 'Specialty',
            required: true,
            options: specialtiesOptions,
            open: openSpeciality,
            onDropdownVisibleChange: (o, field) => {
              setOpenSpeciality(o ? field : '');
            },
            onChange: () => setOpenSpeciality(''),
            labelInValue: true,
            className: 'single-select-tags',
            maxTagCount: 1,
            mode: SELECT_TYPES.CREATABLE,
            itemProps: { rules: [REQUIRED_RULE, VALIDATION_MAX_100] },
          }}
          secondSelectProps={{
            name: 'level',
            label: 'Experience level',
            options: EXPERIENCE_LEVEL_OPTIONS.slice(0, -1),
            allowClear: false,
          }}
          maxCount={10}
        />
      </Form>
    </Popup>
  );
};

const PopupAddDocuments = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = data => {
    onSave({
      cvs: data.cvs.map(cv => (cv.uuid ? cv : { ...cv, filename: cv.name, uploaded: cv.lastModified })),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Documents"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-cvs',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-cvs" form={form} initialValues={initialValues} onFinish={onFinish}>
        <Upload
          accept="application/pdf, .jpg, .doc, .docx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation"
          label="Documents"
          multiple
          maxCount={5}
          itemProps={{ name: 'cvs' }}
        />
      </Form>
    </Popup>
  );
};

const PopupAddCVLinks = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = data => {
    onSave({
      cvs_links: [data.cv_link_0, data.cv_link_1].filter(link => !!link),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="CV Links"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-cv-links',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-cv-links" form={form} initialValues={initialValues} onFinish={onFinish}>
        <Input label="CV links" placeholder="Please past CV link" itemProps={{ name: 'cv_link_0' }} />
        <Input placeholder="Please past CV link" itemProps={{ name: 'cv_link_1' }} />
      </Form>
    </Popup>
  );
};

const PopupAddTags = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const tags = useSelector(getCandidateTags);
  const tagsOptions = useMemo(() => tags.map(el => ({ value: el.uuid, label: el.name })), [tags]);

  const onFinish = data => {
    onSave({ tags: data.tags.map(el => el.label || el.value) });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Tags"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-tags',
        htmlType: 'submit',
      }}
    >
      <Form name="form-edit-tags" form={form} initialValues={initialValues} onFinish={onFinish}>
        <Select
          labelInValue
          label="Tags"
          mode={SELECT_TYPES.CREATABLE}
          options={tagsOptions}
          itemProps={{ name: 'tags', rules: [VALIDATION_MAX_100] }}
        />
      </Form>
    </Popup>
  );
};

const PopupAddWorkingExperience = ({ open, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();

  const positions = useSelector(getPositions);
  const companies = useSelector(getCandidateCompanies);

  const positionsOptions = useMemo(() => positions.map(el => ({ value: el, label: el })), [positions]);
  const companiesOptions = useMemo(() => companies.map(el => ({ value: el.uuid, label: el.name })), [companies]);

  const onChangeExperienceCurrent = key => {
    const fields = form.getFieldsValue();
    const { experience } = fields;
    Object.assign(experience[key] || {}, { worked_till: moment() });
    form.setFieldsValue({ experience });
  };

  const onValueChange = ({ experience: exp }, { experience }) => {
    if (exp) {
      form.setFieldValue(
        'experience',
        experience.map((el, idx) => {
          const isFrom = !!exp[idx]?.worked_from;
          const isTill = !!exp[idx]?.worked_till;
          const worked_from = exp[idx]?.worked_from || el?.worked_from || moment();
          const worked_till = exp[idx]?.worked_till || el?.worked_till || moment();
          return {
            ...el,
            worked_from: isTill && worked_from.isAfter(worked_till) ? worked_till : worked_from,
            worked_till: isFrom && worked_till.isBefore(worked_from) ? worked_from : worked_till,
            company: el?.company?.slice(-1).map(c => ({ ...c, label: c.label || c.value })),
            position: el?.position?.slice(-1),
          };
        })
      );
    }
  };

  const onFinish = data => {
    onSave({
      experience: data.experience
        .filter(ex => ex.company?.length && ex.position?.length)
        .map(ex => ({
          company: ex.company[0].label,
          position: ex.position[0].value,
          worked_from: ex.worked_from ? ex.worked_from.format('YYYY-MM-DD') : null,
          worked_till: ex.worked_till ? ex.worked_till.format('YYYY-MM-DD') : null,
          is_current: ex.is_current,
          additional_info: ex.additional_info,
        })),
    });
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [open]);

  return (
    <Popup
      open={open}
      title="Working experience"
      className="popup-edit-working-experiences"
      cancelButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      okButtonProps={{
        children: 'Save',
        form: 'form-edit-working-experience',
        htmlType: 'submit',
      }}
    >
      <Form
        name="form-edit-working-experience"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValueChange}
      >
        <ExperienceList
          onChangeExperienceCurrent={onChangeExperienceCurrent}
          companiesOptions={companiesOptions}
          positionsOptions={positionsOptions}
          maxCount={10}
          form={form}
        />
      </Form>
    </Popup>
  );
};
