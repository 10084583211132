import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '_atoms';
import { BreadCrumbs } from '_atoms/BreadCrumbs';
import { ExperienceTabContent } from '_molecules';
import { CandidateDetails } from '_organisms';
import { clearCandidateData } from 'store/reducers/candidateReducer';
import { getCandidateData } from 'store/selectors/candidateSelectors';
import { getCandidateInfo } from '../../actions';
import { MixpanelService } from '../../helpers/Mixpanel';
import './style.scss';

export const CandidateProfilePreview = ({ isSSC }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const candidateData = useSelector(getCandidateData);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(getCandidateInfo(id, isSSC, true));
    const mixpanel = new MixpanelService();
    mixpanel.track('View Page.Candidate Profile', {
      'Candidate ID': id,
    });

    return () => {
      dispatch(clearCandidateData());
    };
  }, []);

  const fullName =
    candidateData.first_name_latin && `${candidateData.first_name_latin} ${candidateData.last_name_latin}`;

  return (
    <div className="wrapper-content candidate-details-page">
      {!isSSC && <BreadCrumbs element="Candidates" elementName={fullName} path="/candidates" />}
      <div className="candidate-details-page__main-info">
        <CandidateDetails candidateData={candidateData} showShare />
        <Card className="candidate-job-experience">
          <div className="candidate-job-experience__content">
            <ExperienceTabContent candidateData={candidateData} />
          </div>
        </Card>
      </div>
    </div>
  );
};
