import React, { useState } from 'react';
import cn from 'classnames';
import { Typography, Avatar, AtsContextMenu, TYPOGRAPHY_WEIGHT, Button, BUTTON_TYPES } from '_atoms';
import { dateFormatter } from 'helpers/dateFormatter';
import { ACTIONS, Can, SUBJECTS } from 'permission';
import { ReactComponent as TimelineLine } from 'dist/icons/TimelineLine.svg';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import { useTimelineItemTitle } from './utils';
import './timelineItem.scss';

export const TimelineItem = ({ data, onAction }) => {
  const isReminder = data.group_name === 'reminder';
  const isInteractions = data.group_name === 'interactions';
  const date = dateFormatter(
    isReminder ? data.datetime : isInteractions ? data.details.datetime : data.created_at || data.created,
    'MMM DD, YYYY [at] HH:mm'
  );

  const isChangeLog = data.type === 'changelog';
  const [showData, setShowData] = useState(!isChangeLog);
  const [showExtra, setShowExtra] = useState(false);
  const onChangeShowData = () => setShowData(o => !o);
  const onChangeExtraShow = () => setShowExtra(o => !o);

  const isComment = data.event_type === 'Comment was left';

  const { title, content, previous_content } = useTimelineItemTitle({ data, datetime: data.datetime });

  return (
    <div className="timeline-item">
      <div className="timeline-item__header">
        <Avatar
          src={
            isReminder
              ? data.recruiter.avatar
              : isInteractions
              ? data.details?.recruiter?.avatar
              : data.author?.profile.avatar
          }
          className="icon"
        />
        <Typography.Title3 className="title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {title}
        </Typography.Title3>
        <div className="actions">
          {date}
          {isComment && (
            <AtsContextMenu>
              <li role="none" onClick={() => onAction('comment-edit', data)}>
                Edit
              </li>
              <li role="none" className="active" onClick={() => onAction('comment-delete', data)}>
                Delete
              </li>
            </AtsContextMenu>
          )}
          {isReminder && (
            <Can I={ACTIONS.CRUD} a={SUBJECTS.REMINDER}>
              <AtsContextMenu>
                <li role="none" onClick={() => onAction(`reminder-edit`, data)}>
                  Edit
                </li>
                <li role="none" onClick={() => onAction(`reminder-complete`, data)}>
                  Complete
                </li>
                <li role="none" className="active" onClick={() => onAction(`reminder-delete`, data)}>
                  Delete
                </li>
              </AtsContextMenu>
            </Can>
          )}
          {isInteractions && (
            <Can I={ACTIONS.CRUD} a={SUBJECTS.REMINDER}>
              <AtsContextMenu>
                <li role="none" onClick={() => onAction(`interaction-edit`, data)}>
                  Edit
                </li>
                <li role="none" className="active" onClick={() => onAction(`interaction-delete`, data)}>
                  Delete
                </li>
              </AtsContextMenu>
            </Can>
          )}
        </div>
      </div>
      {showData && (
        <div className="timeline-item__body">
          <table className="info-table">
            <tbody>
              {content.map(({ field, value, prevValue }) => (
                <tr>
                  <td className="info-table__name">{field}:</td>
                  <td className="info-table__data">
                    <span className="previous-value">{prevValue}</span>
                    {prevValue ? <TimelineLine className="arrow" /> : ''}
                    <span className="current-value">{value}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!!Object.keys(previous_content).length && (
            <div className={cn('previous-data', { hidden: !showExtra })}>
              {previous_content.created_at && <span className="created-at">{previous_content.created_at}</span>}
              <table className="info-table">
                <tbody>
                  {previous_content.fields.map(({ field, value }) => (
                    <tr>
                      <td className="info-table__name">{field}:</td>
                      <td className="info-table__data">{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button type={BUTTON_TYPES.TEXT} onClick={onChangeExtraShow}>
                {showExtra ? 'hide' : 'show'} previous version <Arrow />
              </Button>
            </div>
          )}
        </div>
      )}
      {isChangeLog && (
        <Button type={BUTTON_TYPES.TEXT} onClick={onChangeShowData}>
          {showData ? 'Hide updates' : 'Show updates'}
        </Button>
      )}
    </div>
  );
};
