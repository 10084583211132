import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import {
  Card,
  DatePicker,
  Input,
  Select,
  RichArea,
  ButtonGroup,
  Button,
  BUTTON_TYPES,
  notification,
  NOTIFICATION_TYPES,
  Popup,
} from '_atoms';
import { BreadCrumbs } from '_atoms/BreadCrumbs';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { FormListDoubleSelect } from '_molecules';
import history from 'helpers/history';
import { ACTIONS, Can, ROLES, UI } from 'permission';
import { getLanguages, getLocations } from 'store/selectors/commonSelectors';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getForeignLanguages, getJobInfo, getListLocations, getOpeningById, setDataOpening } from 'actions';
import { candidateLevels } from 'constants/constants';
import { OPENING_STATUSES } from 'constants/opening';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import {
  EMPLOYMENT_TYPE_OPTIONS,
  INITIAL_VALUES,
  LEVEL_OPTIONS,
  PRIORITY_OPTIONS,
  RULES,
  WORK_EXPERIENCE_OPTIONS,
  EXPECTED_DATES,
} from './constants';
import { formValuesMapper, initialValuesMapper, teamLeadOptionsMapper, validatorL } from './utils';
import './style.scss';

export const CreateOpening = ({ isClone }) => {
  const { id, openingId } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const [searchOfficeLocations, setSearchOfficeLocations] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState({});
  const [teamLeadOptions, setTeamLeadOptions] = useState([]);
  const locations = useSelector(getLocations).map(location => ({
    value: location.uuid,
    label: location.name,
  }));
  const languages = useSelector(getLanguages).map(value => ({ value, label: value }));

  const title = openingId ? (isClone ? 'Copy opening' : 'Edit opening') : 'Opening creation';

  const editSH_RM_Status =
    user.active_role.name !== ROLES.SUPER_ADMIN
      ? isClone ||
        !opening.status ||
        [OPENING_STATUSES.SH_APPROVAL, OPENING_STATUSES.RM_APPROVAL].includes(opening.status)
      : true;

  const isOpenWaitingEntry =
    opening.status === OPENING_STATUSES.OPEN || opening.status === OPENING_STATUSES.WAITING_FOR_ENTRY;
  const disablePrev =
    opening &&
    Math.abs(moment(opening.expected, 'YYYY-MM-DD').diff(moment(opening.hrl_approval_date), 'days')) <=
      Math.abs(EXPECTED_DATES[opening.level]?.diff(moment(), 'days'));

  useEffect(() => {
    dispatch(getJobInfo(id)).then(resp => {
      form.setFields([{ name: 'job_name', value: resp.name }]);
      setTeamLeadOptions(teamLeadOptionsMapper(resp));
    });
    if (openingId) {
      setLoading(true);
      getOpeningById(id, openingId)
        .then(data => {
          setOpening(data);
          return data;
        })
        .then(initialValuesMapper)
        .then(data => {
          if (isClone)
            form.setFields([
              ...data.filter(d => d.name !== 'expected'),
              {
                name: 'expected',
                value:
                  EXPECTED_DATES[data.find(d => d.name === 'experience_level').value.value || candidateLevels[0].value],
              },
            ]);
          else form.setFields(data);
          setLoading(false);
        });
    }
    dispatch(getForeignLanguages());
    dispatch(getListLocations({ job: id }));
  }, []);

  const onCloseExitConfirmation = () => setOpenConfirmation(false);

  const toOpeningsList = () => history.push(`/jobs/${id}/show/openings`);

  const onGoBack = () => {
    const { comment, requirements_responsibilities, ...touchedValues } = form.getFieldsValue(
      null,
      meta => meta.touched
    );
    if (
      comment !== form.getFieldValue('comment_initial') ||
      requirements_responsibilities !== form.getFieldValue('requirements_responsibilities_initial') ||
      Object.keys(touchedValues).length
    ) {
      setOpenConfirmation(true);
    } else {
      toOpeningsList();
    }
  };

  const onValueChange = ({ experience_level }, { expected }) => {
    if (user.active_role.name === ROLES.SUPER_ADMIN || !experience_level) return;
    if (expected && EXPECTED_DATES[experience_level.value].isAfter(expected)) {
      form.setFieldValue('expected', EXPECTED_DATES[experience_level.value]);
      notification({
        message: 'Note that the expected date of the opening has been updated automatically',
        type: NOTIFICATION_TYPES.WARNING,
      });
    } else if (!expected) {
      form.setFieldValue('expected', EXPECTED_DATES[experience_level.value]);
    }
  };

  const onFinish = values => {
    setLoading(true);
    setDataOpening(id, isClone ? undefined : openingId, formValuesMapper(values))
      .then(() => {
        notification({
          type: NOTIFICATION_TYPES.SUCCESS,
          message: `Opening expected on ${values.expected.format('MMM DD, YYYY')} has been ${
            openingId ? (isClone ? 'copied' : 'changed') : 'created'
          }.`,
        });
        toOpeningsList();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetField = field => () => form.setFields([{ name: field, value: '' }]);
  const changeValue = (name, value) => form.setFieldValue(name, value);

  return (
    <div className="wrapper-content opening-creation__wrapper">
      <BreadCrumbs element="Jobs" elementName={title} path="/jobs" />
      <Form
        className="opening-creation__form"
        form={form}
        onFinish={onFinish}
        initialValues={INITIAL_VALUES}
        validateTrigger="onSubmit"
        onValuesChange={onValueChange}
      >
        <div className="opening-creation__header-wrapper">
          <Card className="opening-creation__header">
            <Typography.Title1>{title}</Typography.Title1>
            <div className="submit-buttons">
              <Button disabled={loading} onClick={onGoBack}>
                Cancel
              </Button>
              <Button htmlType="submit" loading={loading} type={BUTTON_TYPES.PRIMARY}>
                {openingId ? 'Save opening' : 'Create opening'}
              </Button>
            </div>
          </Card>
        </div>
        <Card className="opening-creation">
          <Typography.Title2 className="opening-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
            General
          </Typography.Title2>
          <Row className="select-block" gutter={[32, 16]}>
            <Col lg={12} md={12} sm={24}>
              <Input label="Job name" disabled itemProps={{ name: 'job_name' }} />
              <Can I={ACTIONS.UPDATE} a={UI.OPENING_TEAM_LEAD} passThrough>
                {editable => (
                  <Select
                    labelInValue
                    label="Team lead"
                    options={teamLeadOptions}
                    itemProps={{ name: 'team_lead', rules: RULES.TEAM_LEAD }}
                    disabled={!editable}
                  />
                )}
              </Can>
              <Can I={ACTIONS.UPDATE} a={UI.OPENING_PRIORITY} passThrough>
                {editable => (
                  <Select
                    labelInValue
                    label="Priority of opening"
                    options={PRIORITY_OPTIONS}
                    itemProps={{ name: 'priority' }}
                    rules={RULES.PRIORITY}
                    disabled={!editable}
                  />
                )}
              </Can>
              <Can I={ACTIONS.UPDATE} a={UI.OPENING_EXPECTED_DATE} passThrough>
                {editable => (
                  <DatePicker
                    label="Expected date"
                    itemProps={{ name: 'expected', rules: RULES.EXPECTED }}
                    disabled={!editable}
                    minDate={
                      user.active_role.name !== ROLES.SUPER_ADMIN && isOpenWaitingEntry
                        ? disablePrev
                          ? moment(opening.hrl_approval_date).add(
                              EXPECTED_DATES[opening.level].diff(moment(), 'days'),
                              'days'
                            )
                          : moment(opening.expected, 'YYYY-MM-DD')
                        : undefined
                    }
                  />
                )}
              </Can>
              <Can I={ACTIONS.UPDATE} a={UI.OPENING_OFFICE_LOCATIONS} passThrough>
                {editable => (
                  <Select
                    labelInValue
                    label="Office location"
                    mode={SELECT_TYPES.MULTIPLE}
                    options={locations}
                    itemProps={{ name: 'offices', rules: RULES.OFFICES }}
                    disabled={!editable || !editSH_RM_Status}
                    searchValue={searchOfficeLocations}
                    onSearch={setSearchOfficeLocations}
                  />
                )}
              </Can>
            </Col>
          </Row>
          <Can I={ACTIONS.UPDATE} a={UI.OPENING_FOREIGN_LANGUAGES} passThrough>
            {editable => (
              <FormListDoubleSelect
                listName="foreign_languages"
                buttonText="Add language"
                firstSelectProps={{
                  name: 'language',
                  label: 'Foreign language',
                  options: languages,
                }}
                secondSelectProps={{
                  name: 'level',
                  options: LEVEL_OPTIONS,
                }}
                maxCount={editable ? languages.length : 0}
                firstSelectValidator={validatorL('level', 'Please select language')}
                secondSelectValidator={validatorL('language', 'Please select language level')}
              />
            )}
          </Can>
          <Can I={ACTIONS.UPDATE} a={UI.OPENING_EMPLOYMENT_TYPE} passThrough>
            {editable => (
              <ButtonGroup
                label="Employment type"
                className="employment-type"
                options={EMPLOYMENT_TYPE_OPTIONS}
                onChange={resetField('employment_custom_value')}
                itemProps={{ name: 'employment' }}
                inputProps={{
                  placeholder: 'hours',
                  maxLength: 2,
                  itemProps: { rules: RULES.EXP_TYPE, name: 'employment_custom_value' },
                  onChange: e => changeValue('employment_custom_value', e.target.value.replace(/\D/g, '')),
                  disabled: !editable,
                }}
                withCustom
                disabled={!editable}
              />
            )}
          </Can>
          <Typography.Title2 className="opening-creation__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Technical part
          </Typography.Title2>
          <Can I={ACTIONS.UPDATE} a={UI.OPENING_WORK_EXPERIENCE} passThrough>
            {editable => (
              <ButtonGroup
                label="Required work experience"
                className="required-work-experience"
                options={WORK_EXPERIENCE_OPTIONS}
                onChange={resetField('work_experience_custom_value')}
                itemProps={{ name: 'work_experience' }}
                inputProps={{
                  placeholder: 'years',
                  maxLength: 5,
                  itemProps: { rules: RULES.WORK_EXP, name: 'work_experience_custom_value' },
                  disabled: !editable,
                }}
                withCustom
                disabled={!editable}
              />
            )}
          </Can>
          <Row className="required-experience-level" gutter={{ lg: 32, md: 32, sm: 16 }}>
            <Col lg={12} md={12} sm={24}>
              <Can I={ACTIONS.UPDATE} a={UI.OPENING_EXPERIENCE_LEVEL} passThrough>
                {editable => (
                  <Select
                    labelInValue
                    label="Required experience level"
                    options={candidateLevels}
                    itemProps={{ name: 'experience_level', rules: RULES.EXPERIENCE_LEVEL }}
                    disabled={!editable || !editSH_RM_Status}
                  />
                )}
              </Can>
            </Col>
            <Can I={ACTIONS.UPDATE} a={UI.OPENING_EXPERIENCE_LEVEL} passThrough>
              {editable => (
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate={(prev, curr) => prev.experience_level !== curr.experience_level}>
                    {({ getFieldValue }) =>
                      getFieldValue().experience_level.value === 'Custom' && (
                        <Input
                          label="Custom required experience level"
                          placeholder="Please enter custom experience level"
                          itemProps={{ name: 'experience_level_custom_value', rules: RULES.EXPERIENCE_LEVEL_CUSTOM }}
                          disabled={!editable}
                          maxLength={20}
                        />
                      )
                    }
                  </Form.Item>
                </Col>
              )}
            </Can>
          </Row>
          <Can I={ACTIONS.UPDATE} a={UI.OPENING_RESPONSIBILITIES} passThrough>
            {editable => (
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.requirements_responsibilities_initial !== curr.requirements_responsibilities_initial
                }
              >
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item
                    className="rich-area-item"
                    name="requirements_responsibilities"
                    rules={RULES.RESPONSIBILITIES}
                  >
                    <RichArea
                      initialValue={getFieldValue('requirements_responsibilities_initial')}
                      label="Requirements/Responsibilities"
                      placeholder="Add requirements and responsibilities"
                      onChange={v => setFieldValue('requirements_responsibilities', v)}
                      disabled={!editable}
                      maxLength={10000}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            )}
          </Can>
          <Can I={ACTIONS.UPDATE} a={UI.OPENING_COMMENT} passThrough>
            {editable => (
              <Form.Item noStyle shouldUpdate={(prev, curr) => prev.comment_initial !== curr.comment_initial}>
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item noStyle name="comment">
                    <RichArea
                      initialValue={getFieldValue('comment_initial')}
                      label={
                        <>
                          Comment <span className="optional">(optional)</span>
                        </>
                      }
                      onChange={v => setFieldValue('comment', v)}
                      placeholder="Add a comment"
                      disabled={!editable}
                      maxLength={10000}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            )}
          </Can>
        </Card>
      </Form>
      <Popup
        open={openConfirmation}
        title={POPUP_LABELS.DISCARD_CHANGES}
        cancelButtonProps={{ onClick: onCloseExitConfirmation, children: BUTTON_TEXT.BACK_TO_PAGE }}
        okButtonProps={{ onClick: toOpeningsList, children: BUTTON_TEXT.DISCARD }}
      >
        {POPUP_BODY.CHANGES_NOT_SAVED}
      </Popup>
    </div>
  );
};
